<template>
    <div class="ProductFilterSelectList d-flex flex-column flex-md-row align-start align-content-md-center">
        <div class="ProductFilterSelectList-filters">
            <v-select
                v-for="filter in appliedFilters"
                :key="`filter-${filter.id}`"
                ref="itemRefs"
                v-model="filter.value"
                :items="getFormattedChoices(filter.filters)"
                :label="filter.name"
                class="ProductFilterSelectList-filter"
                clearable
                item-title="text"
                item-value="value"
                @update:model-value="onFilterChanged"
                @click:clear="onClear(_uid)"
            />
        </div>
        <div class="pt-4">
            <v-btn
                class="ProductFilterSelectList-resetLink"
                color="primary"
                variant="text"
                @click="clearAllFilters"
            >
                {{ $t('button:reset_all') }}
            </v-btn>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useCatalogUrlFilters } from '@/StoreWeb/js/composables/catalog-filters'
import { useDisplay } from 'vuetify'

const { lgAndUp } = useDisplay()
const store = useStore()
const itemRefs = ref([])

const getFilters = computed(() => store.state.catalogModule.filters)

const { applyFiltersFromQueryParams, resetFilters, updateFilters, appliedFilters } = useCatalogUrlFilters()

onMounted(() => {
    applyFiltersFromQueryParams(getFilters)
})

function onFilterChanged () {
    if (lgAndUp.value) {
        updateFilters()
    }
}

function clearAllFilters () {
    resetFilters()
}

function getFormattedChoices (array) {
    return array.map(item => ({
        text: item.name,
        value: item.id
    }))
}

function onClear (id) {
    const select = itemRefs.value.find(select => select.$parent._uid === id)
    if (select) {
        select.focus()
        select.blur()
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.ProductFilterSelectList {
    gap: $s4;

    &-filters {
        display: grid;
        grid-template-columns: 1fr;
        gap: $s4;
        width: 100%;

        @media #{map-get($display-breakpoints, 'sm-only')} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media #{map-get($display-breakpoints, 'md-and-up')} {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &-filter {
        width: 100%;

        @media #{map-get($display-breakpoints, 'md-only')} {
            min-width: 200px;
        }

        @media #{map-get($display-breakpoints, 'lg-and-up')} {
            min-width: 250px;
        }
    }

    &-resetLink {
        :deep(.v-btn__content) {
            white-space: nowrap !important;
        }
    }
}
</style>
