<template>
    <div class="SaleOptionProviderAccountAndFareMediaChoice">
        <div v-if="getShowSupportSelector">
            <div v-if="showFareMedia">
                <p
                    v-if="!isEmptyTrans('select:support')"
                    class="h3 text-center SaleOptionProviderAccountAndFareMediaChoice-title"
                >
                    {{ $t('select:support') }}
                </p>
                <p class="h3 mb-4">
                    {{ $t('choose:card') }}
                </p>
                <ul class="SaleOptionProviderAccountAndFareMediaChoice-list">
                    <sale-option-provider-account-and-fare-media-choice-item
                        v-for="fareMedia in getFareMedias"
                        :key="fareMedia.id"
                        :disabled="isLoading"
                        :fareMedia="fareMedia"
                        :isActive="isActive(fareMedia)"
                        class="SaleOptionProviderAccountAndFareMediaChoice-item"
                        @fare-media-clicked="fareMediaClicked"
                    />
                </ul>
            </div>
            <div
                v-if="showAssociatedAccounts"
                class="mt-2"
            >
                <p class="h3">
                    {{ $t('account:associated_accounts:title') }}
                </p>
                <ul class="SaleOptionProviderAccountAndFareMediaChoice-list">
                    <sale-option-provider-account-and-fare-media-choice-item
                        v-for="fareMedia in fareMediasAssociatedAccount"
                        :key="fareMedia.id"
                        :disabled="isLoading"
                        :fareMedia="fareMedia"
                        :isActive="isActive(fareMedia)"
                        class="SaleOptionProviderAccountAndFareMediaChoice-item"
                        @fare-media-clicked="fareMediaClicked"
                    />
                </ul>
            </div>
            <alert-message
                v-if="showAlertMessage"
                :message="$t('sale_option:basket_info:provider_account_and_faremedia:no_available_item')"
                type="error"
                class="SaleOptionProviderAccountAndFareMediaChoice-noAvailableTargetError"
            />
            <sale-option-no-provider-account-available
                v-else-if="showNoProviderAccountMessage"
                :provider="provider"
                @provider-accounts-available="providerAccountsAvailable"
            />
            <div
                v-else-if="showNoFareMediaAvailableMessage"
            >
                <p
                    v-if="$t('select:support') !== '' && $t('select:support') !== 'select:support'"
                    class="h3 text-center SaleOptionProviderAccountAndFareMediaChoice-noAvailableTargetTitle"
                >
                    {{ $t('support:order:title') }}
                </p>
                <div class="backgroundWithLinearGradient pa-15 text-center align-center">
                    <p class="mb-5">
                        {{ $t('card_order_desc') }}
                    </p>
                    <v-btn
                        class="font-weight-bold align-content-center"
                        color="primary"
                        size="large"
                        @click="redirectToCard"
                    >
                        <v-icon
                            icon="mdi-card-account-details-outline"
                            class="mr-1"
                        />
                        {{ $t('card_order_label') }}
                    </v-btn>
                </div>
            </div>
        </div>
        <div v-else>
            <sale-option-anonymous-fare-media-choice
                class="SaleOptionProviderAccountAndFareMediaChoice-anonymousFareMediaForm"
                @anonymous-fare-media-updated="anonymousFareMediaUpdated"
            />
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUpdate } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { isEmpty, isEmptyTranslation } from 'global-utils'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import SaleOptionProviderAccountAndFareMediaChoiceItem from '@/StoreWeb/components/product/SaleOptionProviderAccountAndFareMediaChoiceItem'
import SaleOptionAnonymousFareMediaChoice from '@/StoreWeb/components/product/SaleOptionAnonymousFareMediaChoice'
import AlertMessage from '@/StoreWeb/components/common/AlertMessage'
import config from 'config'
import SaleOptionNoProviderAccountAvailable from '@/StoreWeb/components/product/SaleOptionNoProviderAccountAvailable'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'
import emitter from 'global-emitter'

const emit = defineEmits(['updateFareMedia', 'fareMediasAvailableInAccount', 'providerAccountsAvailable'])

const props = defineProps({
    isLoading: {
        type: Boolean,
        default: false
    },
    provider: {
        type: String,
        default: ''
    }
})

const router = useRouter()
const store = useStore()
const { t } = useI18n()
const { isAuthenticated } = useLogin()

const fareMedias = ref([])
const selectedFareMedia = ref(null)
const fareMediasAssociatedAccount = ref([])

const getAddToCartFlowContextType = computed(() => store.getters.getAddToCartFlowContextType)
const getUserStatus = computed(() => store.getters.getUserStatus)
const getWallet = computed(() => store.getters.getWallet)
const getWalletProviders = computed(() => store.getters.getWalletProviders)
const addToCartFlowContext = computed(() => store.state.cartModule.addToCartFlowContext)
const defaultSelectedFareMedia = computed(() => store.state.userModule.selectedFareMedia)
const getFareMedias = computed(() => fareMedias.value)
const getShowSupportSelector = computed(() => isAuthenticated.value && !isEmpty(getUserStatus.value))
const showFareMedia = computed(() => fareMedias.value.length > 0)
const showAssociatedAccounts = computed(() => fareMediasAssociatedAccount.value.length > 0)
const showAlertMessage = computed(() => config.purchase_flow.type === 'sytral' && getFareMedias.value.length <= 0 && !showAssociatedAccounts.value)
const showNoFareMediaAvailableMessage = computed(() => config.purchase_flow.type === 'default' && getFareMedias.value.length <= 0 && !showAssociatedAccounts.value && !defaultSelectedFareMedia.value)
const showNoProviderAccountMessage = computed(() => {
    const provider = getWalletProviders.value.find(provider => provider.id === props.provider)
    return config.purchase_flow.type === 'default' && provider?.accounts.length <= 0
})

watch(() => props.provider, () => {
    setFareMedias()
    setSelectedFareMedia({ renew: true })
})

onMounted(() => {
    store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)
    setFareMedias()
    autoSelectFareMediaIfPossible()
    if (!showFareMedia.value && !showAssociatedAccounts.value) {
        emit('fareMediasAvailableInAccount', false)
    } else {
        emit('fareMediasAvailableInAccount', true)
    }
})

onBeforeUpdate(() => {
    if (isEmpty(fareMedias.value)) {
        setFareMedias()
    }
    autoSelectFareMediaIfPossible()
})

function anonymousFareMediaUpdated (fareMedia) {
    emit('updateFareMedia', {
        basketInfo: 'PROVIDERACCOUNTANDFAREMEDIA',
        value: normalizeFareMedia(fareMedia)
    })
}

function autoSelectFareMediaIfPossible () {
    setSelectedFareMedia()
}

function fareMediaClicked (fareMedia) {
    selectedFareMedia.value = fareMedia.fareMedia.id
    emit('updateFareMedia', {
        basketInfo: 'PROVIDERACCOUNTANDFAREMEDIA',
        value: fareMedia.fareMedia
    })
}

function normalizeFareMedia (fareMedia) {
    let fareMediaFirstName = ''
    let fareMediaLastName = ''
    let providerUserFirstName = ''
    let providerUserLastName = ''

    if (!isEmpty(fareMedia.fareMedia)) {
        if (!isEmpty(fareMedia.firstName)) {
            fareMediaFirstName = fareMedia.firstName
        } else if (!isEmpty(fareMedia.recipientUser) && !isEmpty(fareMedia.recipientUser.firstName)) {
            fareMediaFirstName = fareMedia.recipientUser.firstName
            providerUserFirstName = fareMediaFirstName
        }
        if (!isEmpty(fareMedia.lastName)) {
            fareMediaLastName = fareMedia.firstName
        } else if (!isEmpty(fareMedia.recipientUser) && !isEmpty(fareMedia.recipientUser.lastName)) {
            fareMediaLastName = fareMedia.recipientUser.lastName
            providerUserLastName = fareMediaLastName
        }

        return {
            fareMediaId: fareMedia.fareMedia.id,
            fareMediaOwnerFirstName: fareMediaFirstName,
            fareMediaOwnerLastName: fareMediaLastName,
            providerId: props.provider,
            providerUserExternalId: fareMedia.account.providerUserExternalId ?? '',
            providerUserId: fareMedia.account.id,
            providerUserFirstName: fareMedia.account.firstName ?? providerUserFirstName,
            providerUserLastName: fareMedia.account.lastName ?? providerUserLastName,
            recipientUser: fareMedia.recipientUser,
            fareMediaType: fareMedia.fareMedia.type
        }
    }

    return {
        fareMediaId: fareMedia,
        fareMediaOwnerFirstName: '',
        fareMediaOwnerLastName: '',
        providerId: props.provider
    }
}

function excludeFareMedia (fareMedias) {
    if (getAddToCartFlowContextType.value === 'FARE_MEDIA_LOADING') {
        return fareMedias.filter(fareMedia => fareMedia.fareMedia.fareMediaId === addToCartFlowContext.value.chosenFareMedia.fareMediaId)
    }

    if (!isEmpty(addToCartFlowContext.value) && !isEmpty(addToCartFlowContext.value.supportToExclude)) {
        return fareMedias.filter(fareMedia => (
            fareMedia.fareMedia.id !== addToCartFlowContext.value.supportToExclude.fareMediaId ||
      fareMedia.provider.id !== addToCartFlowContext.value.supportToExclude.providerId || fareMedia.fareMedia.recipientUser.associationId === 0
        ))
    }

    fareMediasAssociatedAccount.value = fareMedias.filter(fareMedia => {
        return fareMedia.fareMedia.recipientUser && fareMedia.fareMedia.recipientUser.associationId !== 0
    })

    return fareMedias.filter(fareMedia => {
        return fareMedia.fareMedia.recipientUser && fareMedia.fareMedia.recipientUser.associationId === 0
    })
}

function isActive (fareMedia) {
    if (selectedFareMedia.value) {
        return selectedFareMedia.value.toString() === fareMedia.fareMedia.id
    }

    return false
}

function setFareMedias () {
    let fareMediasData = getWallet.value.getAllSupports({
        correspondingBasketInfos: 'PROVIDERACCOUNTANDFAREMEDIA',
        provider: props.provider
    })

    if (!isEmpty(defaultSelectedFareMedia.value)) {
        let isInWallet = false
        fareMediasData.forEach(fareMedia => {
            if (fareMedia.fareMedia.id === defaultSelectedFareMedia.value.id) {
                isInWallet = true
            }
        })

        if (!isInWallet) {
            const selectedFareMediaData = defaultSelectedFareMedia.value
            if (!isEmpty(defaultSelectedFareMedia.value.providerUserId) && !isEmpty(getWallet.value)) {
                const providerData = getWallet.value.providers.find(provider => provider.id === defaultSelectedFareMedia.value.providerId)
                if (!isEmpty(providerData)) {
                    const providerAccountData = providerData.accounts.find(account => account.id === defaultSelectedFareMedia.value.providerUserId)
                    if (!isEmpty(providerAccountData)) {
                        fareMediasData.unshift({
                            account: providerAccountData,
                            correspondingBasketInfos: ['PROVIDERACCOUNTANDFAREMEDIA', 'PROVIDERFAREMEDIA'],
                            correspondingTargets: ['CARD'],
                            fareMedia: selectedFareMediaData,
                            icon: 'Card',
                            index: 0,
                            provider: providerData,
                            title: selectedFareMediaData.getTitle(),
                            subtitle: selectedFareMediaData.getSubtitle()
                        })
                    }
                }
            }
        } else {
            const sortedFareMediasData = []
            fareMediasData.forEach(fareMedia => {
                if (defaultSelectedFareMedia.value.id === fareMedia.fareMedia.id) {
                    sortedFareMediasData.push(fareMedia)
                }
            })
            fareMediasData.forEach(fareMedia => {
                if (defaultSelectedFareMedia.value.id !== fareMedia.fareMedia.id) {
                    sortedFareMediasData.push(fareMedia)
                }
            })
            let index = 1
            sortedFareMediasData.forEach(fareMedia => {
                fareMedia.index = index
                index++
            })
            fareMediasData = sortedFareMediasData
        }
    }

    fareMedias.value = excludeFareMedia(fareMediasData)
}

function setSelectedFareMedia ({ fareMedia = null, renew = false } = {}) {
    if (fareMedia) {
        selectedFareMedia.value = fareMedia.fareMediaId
        const selectedFareMediaData = normalizeFareMedia(fareMedia)

        emit('updateFareMedia', {
            basketInfo: 'PROVIDERACCOUNTANDFAREMEDIA',
            goToNext: true,
            value: selectedFareMediaData
        })
    } else {
        if (renew && !selectedFareMedia.value && !isEmpty(getFareMedias.value)) {
            const [fareMediaData] = getFareMedias.value
            selectedFareMedia.value = fareMediaData.fareMedia.id
            const selectedFareMediaData = normalizeFareMedia(fareMediaData)

            emit('updateFareMedia', {
                basketInfo: 'PROVIDERACCOUNTANDFAREMEDIA',
                value: selectedFareMediaData
            })
        }
        if (!renew && !selectedFareMedia.value && !isEmpty(defaultSelectedFareMedia.value)) {
            if (defaultSelectedFareMedia.value.providerFareMediaId) {
                selectedFareMedia.value = defaultSelectedFareMedia.value.providerFareMediaId
            } else if (defaultSelectedFareMedia.value.fareMediaId) {
                selectedFareMedia.value = defaultSelectedFareMedia.value.fareMediaId
            }

            emit('updateFareMedia', {
                basketInfo: 'PROVIDERACCOUNTANDFAREMEDIA',
                value: defaultSelectedFareMedia.value
            })
        }
    }
}

function isEmptyTrans (translationKey) {
    return isEmptyTranslation(translationKey)
}

function redirectToCard () {
    // emitter.emit('saveProductToPutOnCard')
    emitter.emit('hideProductModal')
    router.push({ name: 'product', params: { id: t('link_to_card:id'), slug: t('link_to_card:slug') } })
}

function providerAccountsAvailable (data) {
    emit('providerAccountsAvailable', data)
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.SaleOptionProviderAccountAndFareMediaChoice {
    width: 100%;

    &-list {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &-item {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-title,
    &-noAvailableTargetTitle {
        font-size: $font-medium;
    }
}
</style>
