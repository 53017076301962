<template>
    <div
        class="ProductDetails"
    >
        <div
            v-if="productDetails !== null"
            class="Container"
        >
            <p class="ProductDetails-back">
                <a
                    class="ProductDetails-backButton"
                    @click="router.go(-1)"
                >
                    {{ $t('button:back') }}
                </a>
            </p>
            <h1 class="h1 ProductDetails-title">
                {{ productDetails.name }}
            </h1>
            <product-keywords
                v-if="keywords.length > 0 && smAndDown"
                :keywords="productDetails.keywords"
                class="ProductDetails-keywords mb-10"
            />
            <product-details-fare-and-actions
                v-if="smAndDown"
                :productDetails="productDetails"
                class="ProductDetails-fareAndActions"
            />
            <div class="ProductDetails-wrapper d-flex justify-space-between align-start gap-8">
                <div class="ProductDetails-infos">
                    <product-keywords
                        v-if="keywords.length > 0 && mdAndUp"
                        :keywords="productDetails.keywords"
                        class="ProductDetails-keywords mb-8"
                    />
                    <div
                        v-if="getDescription"
                        class="ProductDetails-description mb-16"
                        v-html="$sanitize(getDescription)"
                    />
                    <a
                        v-if="isFareMediaRebuild"
                        :href="sanitizeUrl(getReconstitutionLink)"
                        target="_blank"
                    >
                        {{ $t('product:details:reconstitution_link') }}
                    </a>
                    <img
                        v-if="getImage"
                        :src="getImage"
                        :alt="getImageAlt"
                        class="ProductDetails-photo"
                    >
                </div>
                <product-details-fare-and-actions
                    v-if="mdAndUp"
                    :productDetails="productDetails"
                    class="ProductDetails-fareAndActions pl-6"
                />
                <product-vanish-modal />
            </div>
        </div>
        <product-modal :context="addToCartContext()" />
    </div>
</template>

<script setup>
import { sanitizeUrl } from '@braintree/sanitize-url'
import { useDisplay } from 'vuetify'
import { computed, nextTick, onBeforeMount, onMounted, onUnmounted, onUpdated, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { isEmpty } from 'global-utils'
import * as catalogActionTypes from '@/StoreWeb/store/modules/catalog/action-types'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import config from 'config'
import { removeSeoMetaTags, updateSeoTags } from '@/StoreWeb/js/mixins/seo.utils'
import ProductDetailsFareAndActions from '@/StoreWeb/components/product/ProductDetailsFareAndActions'
import ProductKeywords from '@/StoreWeb/components/product/ProductKeywords'
import ProductVanishModal from '@/StoreWeb/components/product/ProductVanishModal'
import ProductModal from '@/StoreWeb/components/product/ProductModal'
import { addToCartContext } from '@/StoreWeb/js/mixins/faremedia-utils'
import { useI18n } from 'vue-i18n'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import { autoSetSelectedFareMedia } from '@/StoreWeb/js/mixins/wallet-utils'
import { getIsAuthenticated } from '@/StoreWeb/js/composables/login-utils'

const store = useStore()
const { smAndDown, mdAndUp } = useDisplay()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const productDetails = computed(() => store.state.catalogModule.productDetails)

const getReconstitutionLink = computed(() => {
    if (config.reconstitution_link) {
        return config.reconstitution_link
    }
    return ''
})
const isFareMediaRebuild = computed(() => {
    return productDetails.value.productType?.id === 'FARE_MEDIA_REBUILD'
})
const getDescription = computed(() => {
    if (!isEmpty(productDetails.value.fareMedia)) {
        return productDetails.value.fareMedia.description
    } else {
        return productDetails.value.description
    }
})
const getImage = computed(() => {
    if (!isEmpty(productDetails.value.fareMedia)) {
        return productDetails.value.fareMedia.image
    } else {
        return productDetails.value.image
    }
})
const getImageAlt = computed(() => {
    if (!isEmpty(productDetails.value.imageAlt)) {
        return productDetails.value.imageAlt
    }
    return ''
})
const keywords = computed(() => {
    if (!isEmpty(productDetails.value.keywords)) {
        return productDetails.value.keywords
    }
    return []
})

function setBodyPaddingButton () {
    nextTick(() => {
        const fareAndButtonsWrapper = document.querySelector('#js-ProductDetails-fareAndButtons')
        if (!isEmpty(fareAndButtonsWrapper)) {
            document.body.style.paddingBottom = fareAndButtonsWrapper.offsetHeight + 'px'
        }
    })
}

function updateHeadSeoTags () {
    if (!isEmpty(productDetails.value)) {
        const title = (productDetails.value.seoTitle !== undefined && productDetails.value.seoTitle !== '') ? productDetails.value.seoTitle : productDetails.value.name
        const description = (productDetails.value.seoMetaDescription !== undefined && productDetails.value.seoMetaDescription !== '') ? productDetails.value.seoMetaDescription : productDetails.value.description
        const imageUrl = (productDetails.value.seoImage !== undefined && productDetails.value.seoImage !== '') ? productDetails.value.seoImage : productDetails.value.image
        const imageAlt = (productDetails.value.seoImageAlt !== undefined && productDetails.value.seoImageAlt !== '') ? productDetails.value.seoImageAlt : productDetails.value.name
        const url = window.location.href
        const twitterSite = config.twitter_site ?? ''
        const locale = navigator.language ?? 'fr_FR'

        updateSeoTags({
            title,
            description,
            imageUrl,
            imageAlt,
            url,
            locale,
            twitterSite
        })
    }
}

async function manageQueryParams () {
    const paramsId = route.params.id

    if (!isEmpty(productDetails.value) && productDetails.value?.id === paramsId) {
        updateHeadSeoTags()
    } else {
        await store.dispatch(catalogActionTypes.LOAD_PRODUCT_DETAILS, { id: paramsId })
        updateHeadSeoTags()
    }
}

watch(() => productDetails.value, () => {
    updateHeadSeoTags()
})

watch(() => route.params, async () => {
    await manageQueryParams()
})

async function getWallet () {
    if (!getIsAuthenticated()) {
        return
    }
    store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, {
        keepShow: true,
        title: t('default_loading:title'),
        subtitle: t('default_loading:subtitle'),
        value: true
    })
    const wallet = await store.dispatch(userActionTypes.GET_WALLET, {
        wsOptions: {
            cacheAsync: false,
            cacheValidity: 0
        }
    }).finally(() => {
        store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { keepShow: false, resetTexts: true, value: false })
    })
    if (config.features.restore_last_fare_media && wallet) {
        autoSetSelectedFareMedia()
    }
}

onBeforeMount(async () => {
    await getWallet()
})

onMounted(async () => {
    await manageQueryParams()
    if (store.getters.getWallet?.providers?.length === 0) {
        await getWallet()
    }
})

onUpdated(() => {
    setBodyPaddingButton()
})

onUnmounted(() => {
    removeSeoMetaTags()
})
</script>

<style lang='scss' scoped>
@import 'globalScss';

.ProductDetails {
    background: $color-lightest;

    &-back {
        font-size: 14px;
        font-weight: bold;

        &Button {
            cursor: pointer;
        }
    }

    &-title {
        margin-top: 10px;
        margin-bottom: 30px;
    }

    &-photo {
        max-width: 100%;
        max-height: 420px;
    }

    &-description {
        overflow-x: auto;
        font-weight: $fontWeight-defaultMedium;
        text-align: justify;
        color: $color-lightText;
    }

    &-fareAndActions {
        margin-bottom: $s8;

        @media #{map-get($display-breakpoints, 'md-and-up')} {
            min-width: 332px;
            max-width: 416px;
            border-left: 1px solid $input-borderColor;
        }
    }
}
</style>
