<template>
    <div
        v-if="cardReaderScriptLoaded"
        class="SupportReader"
    >
        <v-stepper-vertical
            v-model="currentStepIndex"
            elevation="0"
            vertical
        >
            <v-stepper-vertical-item
                :complete="currentStepIndex > stepInitConnectionIndex"
                :value="stepInitConnectionIndex"
            >
                <template #title>
                    {{ $t('card_reader:init_connection:title') }}
                </template>
                <template #default>
                    <v-card
                        :loading="showLoader"
                        border
                    >
                        <v-progress-linear
                            :active="showLoader"
                            indeterminate
                        />
                        <v-card-text>
                            <p
                                v-if="stepInitConnectionInformations !== ''"
                                class="SupportReader-informations"
                                v-html="$sanitize(stepInitConnectionInformations)"
                            />
                            <template v-if="stepInitConnectionIssueType === 'INIT_FAILURE'">
                                <p>
                                    {{ $t('card_reader:init_connection:init_failure:instructions:message') }}
                                </p>
                                <v-btn
                                    class="w-100"
                                    color="primary"
                                    elevation="0"
                                    @click="callInit"
                                >
                                    {{ $t('card_reader:reinit_button_text') }}
                                </v-btn>
                            </template>
                        </v-card-text>
                    </v-card>
                </template>
                <template #prev />
                <template #next />
            </v-stepper-vertical-item>

            <v-stepper-vertical-item
                :complete="currentStepIndex > stepPrerequisitesInstallationIndex"
                :value="stepPrerequisitesInstallationIndex"
            >
                <template #title>
                    {{ $t('card_reader:prerequisites:title') }}
                </template>
                <template #default>
                    <v-btn
                        class="mb-6 w-100"
                        color="primary"
                        variant="outlined"
                        @click="goToStepLaunch"
                    >
                        {{ $t('card_reader:prerequisites:pass_step_button_text') }}
                    </v-btn>
                    <v-card
                        class="mb-6"
                        border
                    >
                        <v-card-title>
                            {{ $t('card_reader:prerequisites:subtitle') }}
                        </v-card-title>
                        <v-card-text>
                            <v-alert
                                v-if="showGetNewXscpVersion"
                                class="text-body-2"
                                color="warning"
                                density="compact"
                                variant="outlined"
                            >
                                {{ t('card_reader:prerequisites:new_version_warning:message') }}
                            </v-alert>
                            <conduent-card-reader-installation
                                :cdnUrl="cdnUrl"
                                :xscpVersion="xscpVersion"
                                @download-x-s-c-p="onDownloadXSCP"
                            />
                        </v-card-text>
                    </v-card>
                    <v-btn
                        class="w-100"
                        color="primary"
                        elevation="0"
                        @click="goToStepLaunch"
                    >
                        {{ $t('card_reader:prerequisites:all_settled_button_text') }}
                    </v-btn>
                </template>
                <template #prev />
                <template #next />
            </v-stepper-vertical-item>

            <v-stepper-vertical-item
                :complete="currentStepIndex > stepLaunchIndex"
                :value="stepLaunchIndex"
            >
                <template #title>
                    {{ $t('card_reader:launch_program:title') }}
                </template>
                <template #default>
                    <v-card
                        :loading="showLoader"
                        border
                    >
                        <v-progress-linear
                            :active="showLoader"
                            indeterminate
                        />
                        <v-card-text>
                            <p
                                v-if="stepLaunchInformations !== ''"
                                class="SupportReader-informations"
                                v-html="$sanitize(stepLaunchInformations)"
                            />
                            <template v-if="stepLaunchIssueType === 'XSCP_NOT_FOUND'">
                                <p>
                                    {{ $t('card_reader:launch_program:xscp_not_found:instructions:message') }}
                                </p>
                                <v-btn
                                    class="w-100"
                                    color="primary"
                                    elevation="0"
                                    @click="callDownloadXSCP"
                                >
                                    {{ $t('card_reader:download_xscp_plugin_button_text') }}
                                </v-btn>
                            </template>
                            <template v-else-if="stepLaunchIssueType === 'XSCP_LAUNCH_FAILURE'">
                                <p>
                                    {{ $t('card_reader:launch_program:xscp_launch_failure:instructions:message') }}
                                </p>
                                <v-btn
                                    class="w-100"
                                    color="primary"
                                    elevation="0"
                                    @click="callLaunchXSCP"
                                >
                                    {{ $t('card_reader:launch_xscp_button_text') }}
                                </v-btn>
                            </template>
                            <template v-else-if="stepLaunchIssueType === 'XSCP_LAUNCH_WAITING' || stepLaunchIssueType === 'XSCP_LAUNCH_DETECT_FAILURE'">
                                <ul class="mb-6">
                                    <li class="mb-2">
                                        {{ $t('card_reader:launch_program:xscp_launch_detect:check_authorisation') }}
                                    </li>
                                    <li class="mb-2">
                                        {{ $t('card_reader:launch_program:xscp_launch_detect:check_already_launched') }}
                                    </li>
                                    <li class="mb-2">
                                        {{ $t('card_reader:launch_program:xscp_launch_detect:not_installed') }}
                                    </li>
                                </ul>
                            </template>
                            <template v-else-if="stepLaunchIssueType === 'XSCP_LAUNCH_WAITING_FAILURE'">
                                <p>
                                    {{ $t('card_reader:launch_program:xscp_launch_waiting_failure:instructions:message') }}
                                </p>
                            </template>
                            <template v-else-if="stepLaunchIssueType === 'XSCP_LAUNCH_OBSOLETE_VERSION'">
                                <p>
                                    {{ $t('card_reader:launch_program:xscp_launch_obsolete_version:instructions:message') }}
                                </p>
                                <v-btn
                                    class="w-100"
                                    color="primary"
                                    elevation="0"
                                    @click="callDownloadXSCP"
                                >
                                    {{ $t('card_reader:download_xscp_plugin_button_text') }}
                                </v-btn>
                            </template>
                        </v-card-text>
                    </v-card>
                </template>
                <template #prev="{prev}">
                    <v-btn
                        class="w-100 pa-0 ma-0 py-0"
                        color="primary"
                        elevation="0"
                        @click="prev"
                    >
                        {{ $t('return') }}
                    </v-btn>
                </template>
                <template #next />
            </v-stepper-vertical-item>

            <v-stepper-vertical-item
                :complete="currentStepIndex > stepDetectReaderIndex"
                :value="stepDetectReaderIndex"
            >
                <template #title>
                    {{ $t('card_reader:detect_reader:title') }}
                </template>
                <template #default>
                    <v-card
                        :loading="showLoader"
                        border
                    >
                        <v-progress-linear
                            :active="showLoader"
                            indeterminate
                        />
                        <v-card-text>
                            <p
                                v-if="stepDetectReaderInformations !== ''"
                                class="SupportReader-informations"
                                v-html="$sanitize(stepDetectReaderInformations)"
                            />
                            <template v-if="stepDetectReaderIssueType === 'READER_DETECTION_CONNECTION_LOST'">
                                <p>
                                    {{ $t('card_reader:connection_lost:instructions:message') }}
                                </p>
                                <v-btn
                                    class="w-100"
                                    color="primary"
                                    elevation="0"
                                    @click="callInit"
                                >
                                    {{ $t('card_reader:reinit_button_text') }}
                                </v-btn>
                            </template>
                            <template v-if="stepDetectReaderIssueType === 'READER_DETECTION_NOT_FOUND'">
                                <p>
                                    {{ $t('card_reader:detect_reader:not_found:instructions:not_plugged:message') }}
                                </p>
                                <v-btn
                                    class="w-100 mb-6"
                                    color="primary"
                                    elevation="0"
                                    @click="callInit"
                                >
                                    {{ $t('card_reader:detect_reader:detect_button_text') }}
                                </v-btn>
                                <p>
                                    {{ $t('card_reader:detect_reader:not_found:instructions:drivers_not_installed:message') }}
                                </p>
                                <v-btn
                                    class="w-100"
                                    color="primary"
                                    elevation="0"
                                    @click="showInstallationStep"
                                >
                                    {{ $t('card_reader:detect_reader:show_install_step_button_text') }}
                                </v-btn>
                            </template>
                        </v-card-text>
                    </v-card>
                </template>
                <template #prev />
                <template #next />
            </v-stepper-vertical-item>

            <v-stepper-vertical-item
                :complete="currentStepIndex > stepDetectCardIndex"
                :value="stepDetectCardIndex"
            >
                <template #title>
                    {{ $t('card_reader:detect_card:title') }}
                </template>
                <template #default>
                    <v-card
                        :loading="showLoader"
                        border
                    >
                        <v-progress-linear
                            :active="showLoader"
                            indeterminate
                        />
                        <v-card-text>
                            <p
                                v-if="stepDetectCardInformations !== ''"
                                class="SupportReader-informations"
                                v-html="$sanitize(stepDetectCardInformations)"
                            />
                            <template v-if="stepDetectCardIssueType === 'CARD_DETECTION_CONNECTION_LOST'">
                                <p>
                                    {{ $t('card_reader:connection_lost:instructions:message') }}
                                </p>
                                <v-btn
                                    class="w-100"
                                    color="primary"
                                    elevation="0"
                                    @click="callInit"
                                >
                                    {{ $t('card_reader:reinit_button_text') }}
                                </v-btn>
                            </template>
                            <template v-if="stepDetectCardIssueType === 'CARD_DETECTION_NOT_FOUND'">
                                <p>
                                    {{ $t('card_reader:detect_card:not_found:instructions:message') }}
                                </p>
                                <v-btn
                                    class="w-100"
                                    color="primary"
                                    elevation="0"
                                    @click="callDetectCard"
                                >
                                    {{ $t('card_reader:detect_card:detect_button_text') }}
                                </v-btn>
                            </template>
                        </v-card-text>
                    </v-card>
                    <template v-if="stepReadCardInformationsStatus === 'error' && stepReadCardInformations !== ''">
                        <v-alert
                            class="mb-3"
                            type="error"
                        >
                            <div
                                v-html="$sanitize(stepReadCardInformations)"
                            />
                        </v-alert>
                        <v-btn
                            class="w-100 mb-0"
                            color="primary"
                            elevation="0"
                            @click="callInit"
                        >
                            {{ $t('card_reader:retry') }}
                        </v-btn>
                    </template>
                </template>
                <template #prev />
                <template #next />
            </v-stepper-vertical-item>

            <template v-if="operationType === 'WRITE'">
                <v-stepper-vertical-item
                    :complete="currentStepIndex > stepUpdateCardIndex || isOperationFinished"
                    :value="stepUpdateCardIndex"
                >
                    <template #title>
                        {{ $t('card_reader:update_card:title') }}
                    </template>
                    <template #default>
                        <template v-if="isOperationFinished && stepUpdateCardInformations !== ''">
                            <v-alert
                                class="mb-6"
                                :type="stepUpdateCardInformationsStatus"
                            >
                                <div
                                    v-html="$sanitize(stepUpdateCardInformations)"
                                />
                            </v-alert>
                            <v-btn
                                v-if="stepUpdateCardInformationsStatus === 'error'"
                                class="w-100 mb-1"
                                color="primary"
                                elevation="0"
                                @click="callInit"
                            >
                                {{ $t('card_reader:retry') }}
                            </v-btn>
                            <v-btn
                                v-else
                                class="w-100"
                                color="primary"
                                elevation="0"
                                @click="seeCardContent"
                            >
                                {{ $t('card_reader:see_card_content_button_text') }}
                            </v-btn>
                        </template>
                        <v-card
                            v-else
                            :loading="!isOperationFinished"
                            border
                        >
                            <template
                                #progress
                            >
                                <v-progress-linear
                                    :active="showLoader"
                                    indeterminate
                                />
                            </template>
                            <v-card-text>
                                <p
                                    v-if="!isOperationFinished && stepUpdateCardInformations !== ''"
                                    class="SupportReader-informations"
                                    v-html="$sanitize(stepUpdateCardInformations)"
                                />
                                <template v-if="stepUpdateCardIssueType === 'READER_DETECTION_CONNECTION_LOST'">
                                    <p>
                                        {{ $t('card_reader:connection_lost:instructions:message') }}
                                    </p>
                                    <v-btn
                                        class="w-100"
                                        color="primary"
                                        elevation="0"
                                        @click="callInit"
                                    >
                                        {{ $t('card_reader:reinit_button_text') }}
                                    </v-btn>
                                </template>
                            </v-card-text>
                        </v-card>
                    </template>
                    <template #prev />
                    <template #next />
                </v-stepper-vertical-item>
            </template>
        </v-stepper-vertical>
    </div>
</template>

<script setup>
import {
    computed,
    nextTick,
    onMounted,
    onUnmounted,
    ref
} from 'vue'
import * as cardReaderActionTypes from '@/StoreWeb/store/modules/card-reader/action-types'
import * as anonymousMutationTypes from '@/StoreWeb/store/modules/anonymous/mutation-types'
import emitter from 'global-emitter'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { isEmpty } from 'global-utils'
import config from 'config'
import { useRouter } from 'vue-router'
import { setSelectedFareMediaCookie } from '@/StoreWeb/js/mixins/wallet-utils'
import * as anonymousActionTypes from '@/StoreWeb/store/modules/anonymous/action-types'

const store = useStore()
const router = useRouter()
const { t } = useI18n()

const props = defineProps({
    operationType: {
        type: String,
        default: 'READ',
        validator: (val) => ['READ', 'WRITE'].includes(val)
    }
})

const emit = defineEmits(['operationFinished', 'pluggedSupportIdUpdated'])

const timeoutDuration = ref(3000)
const currentStepIndex = ref(1)
const isCardReaderInstallStepDone = ref(false)
const isOperationFinished = ref(false)
const cardReaderName = ref('')
const cardReaderScriptLoaded = ref(false)
const currentOperationType = ref('READ')
const xscpVersion = ref(config?.support_reader?.xscp_version ?? 'V02.01')

const stepInitConnectionIndex = ref(1)
const stepInitConnectionInformations = ref('')
const stepInitConnectionIssueType = ref('')

const stepPrerequisitesInstallationIndex = ref(2)

const stepLaunchIndex = ref(3)
const stepLaunchInformations = ref('')
const stepLaunchIssueType = ref('')

// const stepDetectReader = ref(null)
const stepDetectReaderIndex = ref(4)
const stepDetectReaderInformations = ref('')
const stepDetectReaderIssueType = ref('')

const stepDetectCard = ref(null)
const stepDetectCardIndex = ref(5)
const stepDetectCardInformations = ref('')
const stepDetectCardIssueType = ref('')

const stepReadCardInformationsStatus = ref('success')
const stepReadCardInformations = ref('')

const stepUpdateCardIndex = ref(6)
const stepUpdateCardInformationsStatus = ref('success')
const stepUpdateCardInformations = ref('')
const stepUpdateCardIssueType = ref('')

const isXscpInitialized = ref(false)
const isXscpLaunching = ref(false)
const isXscpLaunched = ref(false)
const launchTimeout = ref(null)
const mediaSerialNumber = ref(null)
const mediaUnicity = ref(null)
const operation = ref(null)
const xscpLaunchingCounter = ref(0)
const showLoader = ref(false)
const showGetNewXscpVersion = ref(false)

const cdnUrl = computed(() => process.env.VUE_APP_CDN_URL)
const debugInformationsDisplay = computed(() => store.state.debuggingModule.debugInformationsDisplay)
const pluggedSupportId = computed(() => store.state.anonymousModule.pluggedSupportId)
const supportContent = computed(() => store.state.anonymousModule.supportContent)

async function resetStepperStepWrapperHeight (step) {
    await nextTick()
    setTimeout(() => {
        if (step?.$el) {
            const $stepDetectCardWrapper = step.$el.querySelector('.v-stepper__wrapper')

            if ($stepDetectCardWrapper) {
                $stepDetectCardWrapper.style.height = 'auto'
            }
        }
    }, 400) // Wait that the css animation is finished before removing fixed height as there is no javascript event
}

// ----------------------------------------------- STEP INIT CONNEXION -------------------------------------------------
function callInit () {
    stepInitConnectionInformations.value = t('card_reader:init_connection:in_progress:message')
    showLoader.value = true
    window.InitXSCP()
}
function onInitXscpHandler (event) {
    if (debugInformationsDisplay.value > 1) {
        // eslint-disable-next-line
        console.log(event.type, event.detail)
    }
}
function onEndInitXscpHandler (event) {
    showLoader.value = false
    reinitData()
    if (debugInformationsDisplay.value > 1) {
        // eslint-disable-next-line
        console.log(event.type, event.detail)
    }

    if (event.detail.response === 0) {
        isXscpInitialized.value = true
        stepInitConnectionInformations.value = t('card_reader:init_connection:success:message')
        stepInitConnectionIssueType.value = ''

        if (isCardReaderInstallStepDone.value) {
            goToStepLaunch()
        } else {
            goToStepPrerequisitesInstallation()
        }
    } else {
        stepInitConnectionInformations.value = t('card_reader:init_connection:fail:message')
        stepInitConnectionIssueType.value = 'INIT_FAILURE'
    }
}
// ------------------------------------------------ STEP INSTALLATION --------------------------------------------------
function goToStepPrerequisitesInstallation () {
    currentStepIndex.value = stepPrerequisitesInstallationIndex.value
}
function showInstallationStep () {
    localStorage.removeItem('isCardReaderInstallStepDone')
    isCardReaderInstallStepDone.value = false
    stepPrerequisitesInstallationIndex.value++
    stepLaunchIndex.value++
    stepDetectReaderIndex.value++
    stepDetectCardIndex.value++
    stepUpdateCardIndex.value++
    currentStepIndex.value = 1
    callInit()
}
function callDownloadXSCP () {
    window.DownloadXSCP(`${window.conduentMobilitySolutionsUrl}/httptunnel/`)
}
function onDownloadXscpHandler (event) {
    if (debugInformationsDisplay.value > 1) {
        // eslint-disable-next-line
        console.log(event.type, event.detail)
    }
}
function onDownloadXSCP () {
    callDownloadXSCP()
}
// ----------------------------------------------- STEP LAUNCH PROGRAM -------------------------------------------------
function goToStepLaunch () {
    currentStepIndex.value = stepLaunchIndex.value
    callLaunchXSCP()
}
function callLaunchXSCP () {
    showLoader.value = true
    stepLaunchInformations.value = t('card_reader:launch_program:launch_in_progress:message')
    window.LaunchXSCP()
}
function callDetectXSCP () {
    showLoader.value = true
    stepLaunchInformations.value = t('card_reader:launch_program:check_launch_in_progress:message')
    window.DetectXSCP()
}
function callGetXSCPVersion () {
    showLoader.value = true
    stepLaunchInformations.value = t('card_reader:launch_program:get_version_in_progress:message')
    window.GetVersion()
}
function onLaunchXscpHandler (event) {
    if (debugInformationsDisplay.value > 1) {
        // eslint-disable-next-line
        console.log(event.type, event.detail)
    }

    if (event.detail.response === -2) {
        stepLaunchInformations.value = t('card_reader:launch_program:xscp_not_found:message')
        stepLaunchIssueType.value = 'XSCP_NOT_FOUND'
    } else if (event.detail.response === -1) {
        stepLaunchInformations.value = t('card_reader:launch_program:xscp_launch_failure:message')
        stepLaunchIssueType.value = 'XSCP_LAUNCH_FAILURE'
    } else {
        stepLaunchInformations.value = t('card_reader:launch_program:waiting_for_authorization:message')
        isXscpLaunching.value = true

        launchTimeout.value = setTimeout(launchingCheck, timeoutDuration.value)
    }
}
async function launchingCheck () {
    callDetectXSCP()

    if (xscpLaunchingCounter.value < 5 && !isXscpLaunched.value && isXscpLaunching.value) {
        xscpLaunchingCounter.value++
        stepLaunchIssueType.value = 'XSCP_LAUNCH_WAITING'
        launchTimeout.value = setTimeout(launchingCheck, timeoutDuration.value)
    } else {
        if (debugInformationsDisplay.value > 1) {
            // eslint-disable-next-line
            console.log('launchingCheck', xscpLaunchingCounter.value, !isXscpLaunched.value, isXscpLaunching.value)
        }

        stepLaunchInformations.value = t('card_reader:launch_program:xscp_launch_waiting_failure:message')
        stepLaunchIssueType.value = 'XSCP_LAUNCH_WAITING_FAILURE'
    }
}
function onEndLaunchXscpHandler (event) {
    showLoader.value = false
    if (debugInformationsDisplay.value > 1) {
        // eslint-disable-next-line
        console.log(event.type, event.detail)
    }

    if (event.detail.response === 0) {
        clearTimeout(launchTimeout.value)
        isXscpLaunching.value = false
        isXscpLaunched.value = true
        stepLaunchInformations.value = event.detail.message
        callDetectXSCP()
    } else {
        stepLaunchInformations.value = t('card_reader:launch_program:launch_failed:message')

        if (event.detail.response === -5) {
            stepLaunchInformations.value = t('card_reader:launch_program:xscp_obsolete_version:message')
            stepLaunchIssueType.value = 'XSCP_LAUNCH_OBSOLETE_VERSION'
        }
    }
}
function onDetectXscpHandler (event) {
    if (debugInformationsDisplay.value > 1) {
        // eslint-disable-next-line
        console.log(event.type, event.detail)
    }

    if (event.detail.response === -1) {
        stepLaunchInformations.value = t('card_reader:launch_program:xscp_launch_failure:message')
        stepLaunchIssueType.value = 'XSCP_LAUNCH_FAILURE'
        clearTimeout(launchTimeout.value)
    }
}
function onEndDetectXscpHandler (event) {
    showLoader.value = false
    if (debugInformationsDisplay.value > 1) {
        // eslint-disable-next-line
        console.log(event.type, event.detail)
    }

    if (event.detail.response === -1) {
        stepLaunchInformations.value = t('card_reader:launch_program:launch_failed:message')
        stepLaunchIssueType.value = 'XSCP_LAUNCH_DETECT_FAILURE'
    } else {
        stepLaunchInformations.value = t('card_reader:launch_program:success:message')
        stepLaunchIssueType.value = ''
        callGetXSCPVersion()
    }
}
function onGetVersionHandler (event) {
    if (debugInformationsDisplay.value > 1) {
        // eslint-disable-next-line
        console.log(event.type, event.detail)
    }
}
function onEndGetVersionHandler (event) {
    if (debugInformationsDisplay.value > 1) {
        // eslint-disable-next-line
        console.log(event.type, event.detail)
    }

    if (typeof event.detail.response === 'string') {
        localStorage.setItem('installedXscpVersion', event.detail.response)
        stepLaunchInformations.value = t('card_reader:launch_program:get_version_success:message').replace('%xscpVersion%', event.detail.response)
        setTimeout(() => {
            goToStepDetectReader()
        }, timeoutDuration.value)
    }
}
// ------------------------------------------------ STEP DETECT READER -------------------------------------------------
function goToStepDetectReader () {
    currentStepIndex.value = stepDetectReaderIndex.value
    callDetectReader()
}
function callDetectReader () {
    showLoader.value = true
    stepDetectReaderInformations.value = t('card_reader:detect_reader:detect_in_progress:message')
    window.detectReader()
}
function callGetReaderName () {
    showLoader.value = true
    stepDetectReaderInformations.value = t('card_reader:detect_reader:identification_in_progress:message')
    window.GetReaderName()
}
function onDetectReaderHandler (event) {
    if (debugInformationsDisplay.value > 1) {
        // eslint-disable-next-line
        console.log(event.type, event.detail)
    }

    if (event.detail.response === -1) {
        stepDetectReaderInformations.value = t('card_reader:connection_lost:message')
        stepDetectReaderIssueType.value = 'READER_DETECTION_CONNECTION_LOST'
    }
}
function onEndDetectReaderHandler (event) {
    if (debugInformationsDisplay.value > 1) {
        // eslint-disable-next-line
        console.log(event.type, event.detail)
    }

    if (event.detail.response >= 1) {
        stepDetectReaderInformations.value = t('card_reader:detect_reader:detection:success:message')
        stepDetectReaderIssueType.value = ''
        callGetReaderName()
    } else {
        showLoader.value = false
        stepDetectReaderInformations.value = t('card_reader:detect_reader:reader_not_found:message')
        stepDetectReaderIssueType.value = 'READER_DETECTION_NOT_FOUND'
    }
}
function onGetReaderNameHandler (event) {
    if (debugInformationsDisplay.value > 1) {
        // eslint-disable-next-line
        console.log(event.type, event.detail)
    }

    if (event.detail.response === -1) {
        stepDetectReaderInformations.value = t('card_reader:connection_lost:message')
        stepDetectReaderIssueType.value = 'READER_DETECTION_CONNECTION_LOST'
    }
}
function onEndGetReaderNameHandler (event) {
    if (debugInformationsDisplay.value > 1) {
        // eslint-disable-next-line
        console.log(event.type, event.detail)
    }

    stepDetectReaderInformations.value = t('card_reader:detect_reader:get_reader_name:success:message').replace('%readerName%', event.detail.response)
    stepDetectReaderIssueType.value = ''
    cardReaderName.value = event.detail.response

    setTimeout(() => {
        showLoader.value = false
        goToStepDetectCard()
    }, timeoutDuration.value)
}
// ------------------------------------------------- STEP DETECT CARD --------------------------------------------------
function goToStepDetectCard () {
    currentStepIndex.value = stepDetectCardIndex.value
    localStorage.setItem('isCardReaderInstallStepDone', 1)
    callDetectCard()
}
function callDetectCard () {
    showLoader.value = true
    stepDetectCardInformations.value = t('card_reader:detect_card:detect_in_progress:message')
    window.detectCard()
}
// Another function exists in Conduent javascript file (window.GetCardSerialNumberWithID(), but it seems that there is
// actually no difference with window.GetCardSerialNumber(), so we use window.GetCardSerialNumber()
function callCardSerialNumber () {
    showLoader.value = true
    stepDetectCardInformations.value = t('card_reader:detect_card:get_card_id_in_progress:message')
    window.GetCardSerialNumber()
}
function onDetectCardHandler (event) {
    if (debugInformationsDisplay.value > 1) {
        // eslint-disable-next-line
        console.log(event.type, event.detail)
    }

    if (event.detail.response === -1) {
        stepDetectCardInformations.value = t('card_reader:connection_lost:message')
        stepDetectCardIssueType.value = 'CARD_DETECTION_CONNECTION_LOST'
    }
}
async function onEndDetectCardHandler (event) {
    showLoader.value = false
    if (debugInformationsDisplay.value > 1) {
        // eslint-disable-next-line
        console.log(event.type, event.detail)
    }

    if (event.detail.response >= 1) {
        stepDetectCardInformations.value = t('card_reader:detect_card:detection:success:message')
        stepDetectCardIssueType.value = ''
        callCardSerialNumber()
    } else {
        stepDetectCardInformations.value = t('card_reader:detect_card:card_not_found:message')
        stepDetectCardIssueType.value = 'CARD_DETECTION_NOT_FOUND'
    }
}
function onGetCardSerialNumberHandler (event) {
    if (debugInformationsDisplay.value > 1) {
        // eslint-disable-next-line
        console.log(event.type, event.detail)
    }

    if (event.detail.response === -1) {
        stepDetectCardInformations.value = t('card_reader:connection_lost:message')
        stepDetectCardIssueType.value = 'CARD_DETECTION_CONNECTION_LOST'
    }
}
async function onEndGetCardSerialNumberHandler (event) {
    if (debugInformationsDisplay.value > 1) {
        // eslint-disable-next-line
        console.log(event.type, event.detail)
    }

    if (isNaN(event.detail.response) || (!isNaN(event.detail.response) && parseInt(event.detail.response) !== -1)) {
        stepDetectCardInformations.value = t('card_reader:detect_card:get_card_id:success:message').replace('%cardId%', event.detail.response)
        stepDetectCardIssueType.value = ''
        store.commit(anonymousMutationTypes.SET_PLUGGED_SUPPORT_ID, event.detail.response)
        const support = {
            providerId: 'conduent',
            fareMediaId: event.detail.response
        }
        emit('pluggedSupportIdUpdated', support)
        emitter.emit('pluggedSupportIdStored')

        setTimeout(async () => {
            await store.dispatch(cardReaderActionTypes.GET_OPERATION, {
                operationType: currentOperationType.value,
                silent: true
            }).then(async (operationResponse) => {
                showLoader.value = false
                operation.value = operationResponse?.data
                callActivateChannel(operationResponse?.data?.operationId, operationResponse?.data?.serverHostname, operationResponse?.data?.serverPort)
                if (props.operationType === 'READ') {
                    await resetStepperStepWrapperHeight(stepDetectCard.value)
                }
            }).catch((error) => {
                showLoader.value = false
                if (props.operationType === 'WRITE') {
                    setErrorMessageOnOperationWrite(error)
                } else {
                    setErrorMessageOnOperationRead(error)
                }
            })
        }, timeoutDuration.value)
    } else {
        showLoader.value = false
        stepDetectCardInformations.value = t('card_reader:detect_card:not_recognized:message')
        stepDetectCardIssueType.value = ''
    }
}
// ------------------------------------------------- STEP UPDATE CARD --------------------------------------------------
function goToStepUpdateCard () {
    currentStepIndex.value = stepUpdateCardIndex.value
    stepUpdateCardInformations.value = t('card_reader:update_card:read_in_progress:message')
}
function callActivateChannel (operationId, url, port) {
    showLoader.value = true
    stepUpdateCardInformations.value = t('card_reader:update_card:activate_channel_in_progress:message')
    window.ActivateChannel(operationId, url, port)
}
function onActivateChannelHandler (event) {
    if (debugInformationsDisplay.value > 1) {
        // eslint-disable-next-line
        console.log(event.type, event.detail)
    }

    // TODO : Check if event.detail.response does not have to be parsed
    if (event.detail.response === -1) {
        stepUpdateCardIssueType.value = 'READER_DETECTION_CONNECTION_LOST'
        stepUpdateCardInformations.value = t('card_reader:connection_lost:message')
    }
}
async function onEndTransferHandler (event) {
    showLoader.value = false

    if (debugInformationsDisplay.value > 1) {
        // eslint-disable-next-line
        console.log(event.type, event.detail)
    }

    stepUpdateCardInformations.value = t('card_reader:update_card:end_transfer:message')

    if (event.detail.response === 0) {
        if (!isEmpty(operation.value)) {
            if (debugInformationsDisplay.value > 1) {
                // eslint-disable-next-line
                console.log(`>>>> operationType : ${props.operationType} / currentOperationType : ${currentOperationType.value}`)
            }

            await callGetOperationReport()
        } else {
            if (debugInformationsDisplay.value > 1) {
                // eslint-disable-next-line
                console.log('No operation found...')
            }
        }
    } else {
        stepUpdateCardInformations.value = t('card_reader:update_card:load:error:message').replace('%errorMessage%', event.detail.message)
    }
}
async function callGetOperationWrite (readOperationReportResponse) {
    currentOperationType.value = 'WRITE'
    mediaSerialNumber.value = readOperationReportResponse?.data.serialNumber
    mediaUnicity.value = readOperationReportResponse?.data.unicityId
    await store.dispatch(cardReaderActionTypes.GET_OPERATION, {
        mediaSerialNumber: mediaSerialNumber.value,
        mediaUnicity: mediaUnicity.value,
        operationType: props.operationType,
        sessionId: operation.value.sessionId,
        silent: true
    }).then((operationResponse) => {
        if (debugInformationsDisplay.value > 1) {
            // eslint-disable-next-line
            console.log('onEndTransferHandler >>> GET_OPERATION WRITE callback', operationResponse)
        }
        operation.value = operationResponse?.data
        callActivateChannel(operationResponse?.data?.operationId, operationResponse?.data?.serverHostname, operationResponse?.data?.serverPort)
    }).catch((error) => {
        isOperationFinished.value = true
        setErrorMessageOnOperationWrite(error)
    })
}
async function callGetOperationReport () {
    const operationReportResponse = await store.dispatch(cardReaderActionTypes.GET_OPERATION_REPORT, {
        operation: operation.value,
        operationType: currentOperationType.value,
        silent: true
    }).catch((error) => {
        if (currentOperationType.value === 'WRITE') {
            setErrorMessageOnOperationWrite(error)
        } else {
            setErrorMessageOnOperationRead(error)
        }
    })

    if (operationReportResponse && props.operationType === 'WRITE' && currentOperationType.value === 'READ') {
        if (debugInformationsDisplay.value > 1) {
            // eslint-disable-next-line
            console.log('onEndTransferHandler >>> GET_OPERATION_REPORT READ callback', operationReportResponse)
        }
        goToStepUpdateCard()
        await callGetOperationWrite(operationReportResponse)
    } else if (operationReportResponse) {
        if (debugInformationsDisplay.value > 1) {
            // eslint-disable-next-line
            console.log('onEndTransferHandler >>> GET_OPERATION_REPORT ' + currentOperationType.value + ' callback ', operationReportResponse)
        }

        isOperationFinished.value = true

        if (operationReportResponse?.data?.mediaStatusResult === 'OK') {
            stepUpdateCardInformations.value = t('card_reader:update_card:load:success:message')
        }

        if (props.operationType === 'READ') {
            const support = {
                providerId: 'conduent',
                fareMediaId: pluggedSupportId.value
            }
            emit('operationFinished', {
                operationReport: {},
                support
            })
        }
    }
}
function callStopXSCP () {
    window.StopXSCP()
}
function onStopXscpHandler (event) {
    if (debugInformationsDisplay.value > 1) {
        // eslint-disable-next-line
        console.log(event.type, event.detail)
    }
}
async function seeCardContent () {
    const params = {
        providerId: 'conduent',
        fareMediaId: pluggedSupportId.value,
        isTemporary: false
    }

    setSelectedFareMediaCookie(params, true)
    if (isEmpty(supportContent.value)) {
        const content = await store.dispatch(anonymousActionTypes.GET_SUPPORT_CONTENT, params)

        if (content?.id) {
            store.commit(anonymousMutationTypes.SET_SUPPORT_CONTENT, content)
        } else {
            await router.push({ name: 'walletLight', params })
        }
    }

    if (!isEmpty(supportContent.value)) {
        await router.push({ name: 'walletLight', params })
    }
}
function setErrorMessageOnOperationRead (error) {
    stepReadCardInformationsStatus.value = 'error'
    stepReadCardInformations.value = t('card_reader:load:error_message')
    if (error.response?.data?.status === 422 || error.response?.data?.status === 404) {
        stepReadCardInformations.value = error.response?.data?.message ?? t('card_reader:load:error_message')
    }
}
function setErrorMessageOnOperationWrite (error) {
    stepUpdateCardInformationsStatus.value = 'error'
    stepUpdateCardInformations.value = t('card_reader:update_card:load:error_message')
    if (error.response?.data?.status === 422 || error.response?.data?.status === 404) {
        stepUpdateCardInformations.value = error.response?.data?.message ?? t('card_reader:update_card:load:error_message')
    }
}
function reinitData () {
    stepReadCardInformationsStatus.value = 'success'
    stepReadCardInformations.value = ''
    currentOperationType.value = 'READ'
    stepUpdateCardInformationsStatus.value = 'success'
    stepUpdateCardInformations.value = ''
    isOperationFinished.value = false
}

onMounted(() => {
    window.conduentMobilitySolutionsUrl = config.support_reader.conduent_mobility_solutions_url
    window.addEventListener('unload', callStopXSCP)
    document.body.addEventListener('card-reader-init-xscp', onInitXscpHandler)
    document.body.addEventListener('card-reader-end-init-xscp', onEndInitXscpHandler)
    document.body.addEventListener('card-reader-launch-xscp', onLaunchXscpHandler)
    document.body.addEventListener('card-reader-end-launch-xscp', onEndLaunchXscpHandler)
    document.body.addEventListener('card-reader-detect-xscp', onDetectXscpHandler)
    document.body.addEventListener('card-reader-end-detect-xscp', onEndDetectXscpHandler)
    document.body.addEventListener('card-reader-download-xscp', onDownloadXscpHandler)
    document.body.addEventListener('card-reader-activate-channel', onActivateChannelHandler)
    document.body.addEventListener('card-reader-end-transfer', onEndTransferHandler)
    document.body.addEventListener('card-reader-get-version', onGetVersionHandler)
    document.body.addEventListener('card-reader-end-get-version', onEndGetVersionHandler)
    document.body.addEventListener('card-reader-detect-reader', onDetectReaderHandler)
    document.body.addEventListener('card-reader-end-detect-reader', onEndDetectReaderHandler)
    document.body.addEventListener('card-reader-detect-card', onDetectCardHandler)
    document.body.addEventListener('card-reader-end-detect-card', onEndDetectCardHandler)
    document.body.addEventListener('card-reader-get-reader-name', onGetReaderNameHandler)
    document.body.addEventListener('card-reader-end-get-reader-name', onEndGetReaderNameHandler)
    document.body.addEventListener('card-reader-get-card-serial-number', onGetCardSerialNumberHandler)
    document.body.addEventListener('card-reader-end-get-card-serial-number', onEndGetCardSerialNumberHandler)
    document.body.addEventListener('card-reader-stop-xscp', onStopXscpHandler)

    function loadScript (url, callback) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.onload = function () {
            callback()
        }

        script.src = url
        document.getElementsByTagName('head')[0].appendChild(script)
    }

    isCardReaderInstallStepDone.value = localStorage.getItem('isCardReaderInstallStepDone')

    const installedXscpVersion = localStorage.getItem('installedXscpVersion')

    if (installedXscpVersion && installedXscpVersion !== config.support_reader.xscp_version) {
        showGetNewXscpVersion.value = true
        isCardReaderInstallStepDone.value = false
    }

    if (isCardReaderInstallStepDone.value) {
        stepPrerequisitesInstallationIndex.value--
        stepLaunchIndex.value--
        stepDetectReaderIndex.value--
        stepDetectCardIndex.value--
        stepUpdateCardIndex.value--
    }

    const cardReaderScripPath = `${process.env.VUE_APP_CDN_URL}js/${process.env.VUE_APP_NETWORK_ID}/conduent-dynamic.js`
    loadScript(cardReaderScripPath, function () {
        cardReaderScriptLoaded.value = true
        callInit()
    })
})
onUnmounted(() => {
    if (cardReaderScriptLoaded.value && isXscpInitialized.value) {
        callStopXSCP()
    }

    window.removeEventListener('unload', callStopXSCP)
    document.body.removeEventListener('card-reader-init-xscp', onInitXscpHandler)
    document.body.removeEventListener('card-reader-end-init-xscp', onEndInitXscpHandler)
    document.body.removeEventListener('card-reader-launch-xscp', onLaunchXscpHandler)
    document.body.removeEventListener('card-reader-end-launch-xscp', onEndLaunchXscpHandler)
    document.body.removeEventListener('card-reader-detect-xscp', onDetectXscpHandler)
    document.body.removeEventListener('card-reader-end-detect-xscp', onEndDetectXscpHandler)
    document.body.removeEventListener('card-reader-download-xscp', onDownloadXscpHandler)
    document.body.removeEventListener('card-reader-activate-channel', onActivateChannelHandler)
    document.body.removeEventListener('card-reader-end-transfer', onEndTransferHandler)
    document.body.removeEventListener('card-reader-get-version', onGetVersionHandler)
    document.body.removeEventListener('card-reader-end-get-version', onEndGetVersionHandler)
    document.body.removeEventListener('card-reader-detect-reader', onDetectReaderHandler)
    document.body.removeEventListener('card-reader-end-detect-reader', onEndDetectReaderHandler)
    document.body.removeEventListener('card-reader-detect-card', onDetectCardHandler)
    document.body.removeEventListener('card-reader-end-detect-card', onEndDetectCardHandler)
    document.body.removeEventListener('card-reader-get-reader-name', onGetReaderNameHandler)
    document.body.removeEventListener('card-reader-end-get-reader-name', onEndGetReaderNameHandler)
    document.body.removeEventListener('card-reader-get-card-serial-number', onGetCardSerialNumberHandler)
    document.body.removeEventListener('card-reader-end-get-card-serial-number', onEndGetCardSerialNumberHandler)
    document.body.removeEventListener('card-reader-stop-xscp', onStopXscpHandler)
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

:deep(.v-stepper-actions) {
    padding: 0;
}

.SupportReader {
    background: $color-lightest;

    :deep(.v-card) {
        margin-bottom: $s4;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-informations {
        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
