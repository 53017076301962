<template>
    <div
        ref="referenceParent"
        class="FileInputFieldWithLabel"
    >
        <label
            :for="`js-file-${fileId}`"
            class="FileInputFieldWithLabel-label"
        >
            <span
                v-if="getFileName === ''"
                class="FileInputFieldWithLabel-text"
            >
                {{ label }}
                <icomoon-icon
                    name="Edit"
                    class="FileInputFieldWithLabel-icon"
                />
            </span>
            <span
                v-if="getFileName === '' && description !== ''"
                class="FileInputFieldWithLabel-description"
            >({{ description }})</span>
            <span
                v-if="isFileInstructionsDisplayed && getFileName === '' && getFileInstructions !== ''"
                class="FileInputFieldWithLabel-fileFormat"
                v-html="$sanitize(getFileInstructions)"
            />
            <em
                v-if="getFileName !== ''"
                class="FileInputFieldWithLabel-fileName"
            >
                {{ getFileName }}
                <icomoon-icon
                    name="Edit"
                    class="FileInputFieldWithLabel-icon"
                />
            </em>
        </label>
        <input
            :id="`js-file-${fileId}`"
            ref="referenceFile"
            alt="file input"
            :aria-label="label"
            :aria-required="isRequired"
            :required="isRequired"
            class="FileInputFieldWithLabel-input"
            type="file"
            tabindex="0"
            @focus="focused=true"
            @blur="focused=false"
            @change="fieldValueChanged"
        >
        <div
            v-if="errorLabel !== ''"
            :aria-label="errorLabel"
            class="FileInputFieldWithLabel-error"
        >
            {{ errorLabel }}
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import FileManager from '@/StoreWeb/managers/FileManager'
import i18n from 'i18n'
import config from 'config'

const emit = defineEmits(['fileInputError', 'fieldValueChanged'])

const props = defineProps({
    isRequired: {
        type: Boolean,
        default: true
    },
    description: {
        type: String,
        default: ''
    },
    isFileInstructionsDisplayed: {
        type: Boolean,
        default: true
    },
    fileName: {
        type: String,
        default: ''
    },
    mimeTypes: {
        type: Array,
        default: () => ['image/jpeg', 'image/png', 'application/pdf']
    },
    label: {
        type: String,
        default: 'forms:fileinput:default_label'
    },
    maxSize: {
        type: Number,
        default: 5000000
    },
    fileId: {
        type: [Number, String],
        required: true
    }
})

const errorLabel = ref('')
const file = ref(null)
const referenceParent = ref(null)
const referenceFile = ref(null)
const document = ref(null)
const getFileName = computed(() => file.value ? file.value.name : (props.fileName !== '' ? props.fileName : ''))
const getFileInstructions = computed(() => FileManager.getFileInstructions(props.mimeTypes, config.features.catalog.file_upload_max_size))

function check () {
    file.value = referenceFile.value.files[0]
    errorLabel.value = ''

    if (typeof file.value === 'undefined') {
        file.value = null
    } else {
        checkFileValid()
    }
}

function checkFileValid () {
    if (!isAuthorizedExtension()) {
        const translation = i18n.global.t('forms:fileinput:wrong_file_type')

        errorLabel.value = translation
        emit('fileInputError', translation)
    } else if (!FileManager.isAuthorizedSize(file.value.size, config.features.catalog.file_upload_max_size)) {
        const translation = FileManager.getFileSizeExceded(config.features.catalog.file_upload_max_size)

        errorLabel.value = translation
        emit('fileInputError', translation)
    }
    if (errorLabel.value !== '') {
        emit('fileInputError', 'forms:fileinput:unknown_error')
        referenceParent.value.classList.add(`${name}--error`)
        file.value = null
    } else {
        emit('fileInputError', false)
        referenceParent.value.classList.remove(`${name}--error`)
    }
}

function fieldValueChanged (event) {
    if (!event.target.files || event.target.files.length === 0) {
        return
    }
    check()
    document.value = {
        id: event.target.id.split('js-file-')[1],
        file: event.target.files[0]
    }
    emit('fieldValueChanged', document.value)
}

function isAuthorizedExtension () {
    return props.mimeTypes.indexOf(file.value.type) !== -1
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.FileInputFieldWithLabel {
    position: relative;

    &-label {
        display: block;
        cursor: pointer;
    }

    &-text {
        display: inline-flex;
        align-items: center;
        position: relative;
        font-size: 14px;
        font-style: italic;
        color: $color-brandPrimary;

        &::after {
            content: '';
            display: block;
            position: absolute;
            right: 5px;
            bottom: 1px;
            left: 0;
            height: 1px;
            background: $color-brandPrimary;
        }
    }

    &-description {
        display: block;
        font-size: 12px;
        color: $color-lightText;
    }

    &-fileFormat {
        display: block;
        font-size: 12px;
        color: $color-lighterText;
    }

    &-fileName {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: $color-brandPrimary;
    }

    &-icon {
        font-size: 20px;
    }

    &-input {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    &-error {
        padding-top: 6px;
        font-size: 14px;
        color: $color-danger;
    }
}
</style>
