import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import { removeSelectedFareMediaCookie } from '@/StoreWeb/js/mixins/wallet-utils'
import emitter from 'global-emitter'
import store from '@/StoreWeb/store/store'

// Init to 10 min of inactivity
const inactivityTime = 10 * 60 * 1000
// Listen every 1s
const debounceTime = 1000
const activityEvents = ['mousemove', 'click', 'scroll', 'keydown', 'touchstart', 'touchmove']
let inactivityTimeout
let debounceTimeout // To store the debounce timer

export function initInactivityEvents () {
    const sessionExpires = localStorage.getItem('sessionExpires')
    const now = Date.now()

    if (sessionExpires && now > sessionExpires) {
        sessionExpired()
    }

    activeInactivityEvents()
    resetInactivityTimeout()
}

export function cancelInactivityEvents () {
    clearTimeout(inactivityTimeout)

    activityEvents.forEach(event => {
        document.removeEventListener(event, debounceResetTimeout)
    })

    localStorage.removeItem('sessionExpires')
}

function activeInactivityEvents () {
    activityEvents.forEach(event => {
        document.addEventListener(event, debounceResetTimeout)
    })
}

// Debounce the resetInactivityTimeout function to avoid too many calls
function debounceResetTimeout () {
    clearTimeout(debounceTimeout)
    debounceTimeout = setTimeout(resetInactivityTimeout, debounceTime)
}

function resetInactivityTimeout () {
    const now = Date.now()

    clearTimeout(inactivityTimeout)
    inactivityTimeout = setTimeout(sessionExpired, inactivityTime)
    localStorage.setItem('sessionExpires', now + inactivityTime)
}

function sessionExpired () {
    store.commit(userMutationTypes.SET_SELECTED_SUPPORT, null)
    store.commit(cartMutationTypes.SET_ADD_TO_CART_EMAIL, '')
    localStorage.removeItem('cartEmail')
    removeSelectedFareMediaCookie()
    emitter.emit('selectedSupportChanged', true)
}
