import CartNormalizer from '@/StoreWeb/normalizers/cart/CartNormalizer'
import CartManager from '@/StoreWeb/managers/CartManagerSingleton'
import { isEmpty } from 'global-utils'
import { getCartFareMedias } from '@/StoreWeb/js/composables/cart-utils'

const cartManager = CartManager.getInstance()

export default {
    getAddToCartFlowContextType (state) {
        if (
            !isEmpty(state.addToCartFlowContext) &&
            !isEmpty(state.addToCartFlowContext.chosenFareMedia) &&
            !isEmpty(state.addToCartFlowContext.flowType) &&
            state.addToCartFlowContext.flowType === 'FARE_MEDIA_LOADING'
        ) {
            return 'FARE_MEDIA_LOADING'
        }
        return 'DEFAULT'
    },
    getCart (state) {
        return CartNormalizer.normalizeCart(state.cart)
    },
    getCartFareMedias (state) {
        if (!isEmpty(state.cart?.baskets)) {
            return getCartFareMedias(state.cart.baskets)
        }

        return []
    },
    getIsPaymentProcessingFlow (state) {
        return cartManager.getIsPaymentProcessingFlow()
    },
    getIsEmptyCart (state) {
        return cartManager.getIsEmptyCart()
    },
    getCartResponse (state) {
        return state.cart
    }
}
