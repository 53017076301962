import { isEmpty } from 'global-utils'
import * as catalogMutationTypes from '@/StoreWeb/store/modules/catalog/mutation-types'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import emitter from 'global-emitter'
import { setSelectedFareMediaCookie } from '@/StoreWeb/js/mixins/wallet-utils'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { ref } from 'vue'

/**
 * The applied filters, that we retrieve from the URL query params (and which will also change via the user interactions).
 */
const appliedFilters = ref([])

/**
 * This composable is used to manage Catalog Filters inside URL as query params (and inside the catalog store).
 */
export const useCatalogUrlFilters = () => {
    // Dependencies
    const router = useRouter()
    const route = useRoute()
    const { t: translate } = useI18n()
    const store = useStore()

    const getFiltersToApply = () => {
        let isFirst = true
        const result = appliedFilters.value.reduce((accumulator, filter) => {
            if (!isEmpty(filter.value) && filter.value !== '' && filter.value !== false) {
                if (filter.isMultiple) {
                    accumulator.filtersToApply.push(filter)
                    accumulator.filtersQueryParamsValue += `${isFirst ? '' : '|'}${filter.id}=${filter.value.map(item => item.value).join(',')}`
                    accumulator.filtersTitle += `${isFirst ? '' : ', '}${filter.id} ${filter.value.map(item => item.text).join(',')}`
                } else {
                    if (filter.displayMode === 'SELECT') {
                        accumulator.filtersQueryParamsValue += `${isFirst ? '' : '|'}${filter.id}=${filter.value.value}`
                        accumulator.filtersTitle += `${isFirst ? '' : ', '}${filter.id} ${filter.value.text}`
                    } else {
                        accumulator.filtersQueryParamsValue += `${isFirst ? '' : '|'}${filter.id}=${filter.value}`
                        accumulator.filtersTitle += `${isFirst ? '' : ', '}${filter.id} ${filter.text}`
                    }
                }
                accumulator.filtersToApply.push(filter)
                isFirst = false
            }

            return accumulator
        }, {
            filtersToApply: [],
            filtersQueryParamsValue: '',
            filtersTitle: ''
        })

        if (result.filtersTitle !== '') {
            result.filtersTitle = translate('catalog:filter:current_title').replace('%filters%', result.filtersTitle)
        }

        return result
    }

    const updateFilters = () => {
        const { filtersToApply, filtersQueryParamsValue } = getFiltersToApply(appliedFilters)

        if (filtersToApply.length === 0) {
            resetFilters(appliedFilters.value)
            return
        }

        router.replace({
            query: {
                ...route.query,
                catalogFilters: filtersQueryParamsValue
            }
        })

        store.commit(catalogMutationTypes.SET_APPLIED_FILTERS, filtersToApply)
    }

    const applyFiltersFromQueryParams = (filters) => {
        const catalogFilters = route.query.catalogFilters || ''
        const resetSelectedSupportFilter = route.query.resetSelectedSupportFilter === 'true'
        const resetAppliedFilters = route.query.resetFilters === 'true'

        appliedFilters.value = filters.value

        if (resetAppliedFilters) {
            resetFilters(appliedFilters, ['catalogFilters'])
        }

        const updatedFilters = []
        let settledFilters = []

        if (!isEmpty(catalogFilters)) {
            settledFilters = catalogFilters.split('|')

            for (const settledFilter of settledFilters) {
                const [filterId, filterValue] = settledFilter.split('=')
                const currentFilter = appliedFilters.value.find(filter => filter.id === filterId)

                if (!currentFilter) {
                    continue
                }

                if (currentFilter.displayMode === 'SEARCH') {
                    updatedFilters.push(settledFilter)
                    currentFilter.value = filterValue
                    continue
                }

                if (currentFilter.isMultiple) {
                    const selectedChoices = filterValue.split(',')

                    if (selectedChoices.some(choice => !currentFilter.filters.some(filter => filter.id === choice))) {
                        continue
                    }

                    updatedFilters.push(settledFilter)
                    const correspondingFilters = currentFilter.filters.filter(filter => selectedChoices.includes(filter.id))
                    currentFilter.value = correspondingFilters.map(filter => ({
                        value: filter.id,
                        text: filter.name
                    }))
                } else {
                    const correspondingFilter = currentFilter.filters.find(filter => filter.id === filterValue)

                    if (correspondingFilter) {
                        updatedFilters.push(settledFilter)

                        if (currentFilter.displayMode === 'SELECT') {
                            currentFilter.value = {
                                text: correspondingFilter.name,
                                value: correspondingFilter.id
                            }
                        } else {
                            currentFilter.value = filterValue
                        }
                    }
                }
            }
        }

        if (resetSelectedSupportFilter) {
            setSelectedFareMediaCookie({
                fareMediaId: 'ALL'
            })

            store.commit(userMutationTypes.SET_SELECTED_SUPPORT, null)
            emitter.emit('selectedSupportChanged')
        }

        const { filtersToApply } = getFiltersToApply(appliedFilters)
        store.commit(catalogMutationTypes.SET_APPLIED_FILTERS, filtersToApply)

        if (updatedFilters.length === settledFilters.length) {
            return
        }

        if (updatedFilters.length === 0) {
            router.push({
                query: {}
            })
            return
        }

        router.push({
            query: {
                catalogFilters: updatedFilters.join('|')
            }
        })
    }

    const resetFilters = (filtersToKeep = []) => {
        const paramsToRemove = ['catalogFilters', 'resetFilters', 'resetSelectedSupportFilter'].filter(param => !filtersToKeep.includes(param))

        const query = {
            ...route.query
        }

        paramsToRemove.forEach(param => delete query[param])

        router.replace({
            query
        })

        for (const filter of appliedFilters.value) {
            filter.value = filter.setDefaultValue()
        }

        store.commit(catalogMutationTypes.SET_APPLIED_FILTERS, [])
    }

    return {
        appliedFilters,
        updateFilters,
        resetFilters,
        applyFiltersFromQueryParams
    }
}
