<template>
    <the-header
        :menu-items="menuItems"
        :configuration="configuration"
        :props-cart="propsCart"
    />
</template>

<script setup>
import { useCmsMenuApi, TheHeader } from '@instant-system/web-harmony-widget'
import configuration from '@/../config/wh-config.json'
import { onMounted, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { useStore } from 'vuex'

const store = useStore()

const { locale } = useI18n()

const { loadHeaderMenuItems } = useCmsMenuApi(fetchFn, configuration)

const menuItems = ref([])

const propsCart = computed(() => store.getters.getCartResponse)

onMounted(async () => {
    menuItems.value = await loadHeaderMenuItems(locale.value)
})

async function fetchFn (url) {
    try {
        const response = await axios.get(url)
        return response.data
    } catch (error) {
        if (error.response && error.response.status !== 500) {
            return []
        } else {
            throw error
        }
    }
}
</script>
