import { isEmpty } from 'global-utils'
import * as actionTypes from '@/StoreWeb/store/modules/user/action-types'
import * as loginActionTypes from 'user/Login/store/action-types'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as mutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import emitter from 'global-emitter'
import cachios from 'cachios'
import LoginManager from 'user/Login/managers/LoginManager'
import * as loginMutationTypes from 'user/Login/store/mutation-types'
import WalletNormalizer from '@/StoreWeb/normalizers/user/wallet/WalletNormalizerSingleton'
import NotificationNormalizer from '@/StoreWeb/normalizers/user/notification/NotificationNormalizer'
import DirectDebitNormalizer from '@/StoreWeb/normalizers/user/direct-debit/DirectDebitNormalizerSingleton'
import OrderNormalizer from '@/StoreWeb/normalizers/user/order/OrderNormalizerSingleton'
import PersonalInfoNormalizer from '@/StoreWeb/normalizers/user/personal-info/PersonalInfoNormalizerSingleton'
import PaginationNormalizer from '@/StoreWeb/normalizers/common/PaginationNormalizerSingleton'
import i18n from 'i18n'
import moment from 'moment'
import config from 'config'
import DebuggingManager from '@/StoreWeb/managers/DebuggingManagerSingleton'
import Wallet from '@/StoreWeb/models/user/wallet/Wallet'
import ErrorManager from '@/StoreWeb/managers/ErrorManager'
import * as addressActionTypes from '@/StoreWeb/store/modules/address/action-types'
import FareMedia from '@/StoreWeb/models/user/wallet/FareMedia'
// import WalletMock from '@/StoreWeb/mocks/get-wallet-migration.json'
// import WalletMock from '@/StoreWeb/mocks/user/get-wallet/get-wallet-57-anis-2024-04-22.json'
// import WalletMock from '@/StoreWeb/mocks/user/get-wallet/get-wallet-57-wettissannoitri-2024-04-05.json'
// import WalletMock from '@/StoreWeb/mocks/user/get-wallet/get-wallet-89-xavier-lecomte-2023-06-19.json'
// import WalletMock from '@/StoreWeb/mocks/user/get-wallet/get-wallet-120-oura-dev-6-2024-03-28.json'
// import WalletMock from '@/StoreWeb/mocks/user/get-wallet/get-wallet-120-oura-dev-9-2024-03-28.json'
// import WalletMock from '@/StoreWeb/mocks/user/get-wallet/get-wallet-120-ouradev16-2024-08-23.json'
// import DirectDebitsMock from '@/StoreWeb/mocks/user/account/get-direct-debits/get-direct-debits-default.json'
// import PersonalInfo from '@/StoreWeb/mocks/user/account/get-personal-info/get-personal-info-julien-default.json'

export default class {
    constructor () {
        this._debuggingManager = DebuggingManager.getInstance()
        this._directDebitNormalizer = DirectDebitNormalizer.getInstance()
        this._bankAccounts = null
        this._invoices = []
        // this._notificationNormalizer = NotificationNormalizer.getInstance()
        this._notifications = []
        this._orderNormalizer = OrderNormalizer.getInstance()
        this._orders = []
        this._paginationNormalizer = PaginationNormalizer.getInstance()
        this._personalInfoNormalizer = PersonalInfoNormalizer.getInstance()
        this._walletNormalizer = WalletNormalizer.getInstance()
        this._selectedFareMedia = null
        this._selectedOrder = null
        this._store = null
        this._wallet = null
    }

    get bankAccounts () {
        return this._bankAccounts
    }

    set bankAccounts (bankAccounts) {
        this._bankAccounts = bankAccounts
    }

    get debuggingManager () {
        return this._debuggingManager
    }

    get directDebitNormalizer () {
        return this._directDebitNormalizer
    }

    get invoices () {
        return this._invoices
    }

    set invoices (invoices) {
        this._invoices = invoices
    }

    get walletNormalizer () {
        return this._walletNormalizer
    }

    // get notificationNormalizer () {
    //     return this._notificationNormalizer
    // }

    get notifications () {
        return this._notifications
    }

    set notifications (notifications) {
        this._notifications = notifications
    }

    get orderNormalizer () {
        return this._orderNormalizer
    }

    get orders () {
        return this._orders
    }

    set orders (orders) {
        this._orders = orders
    }

    get paginationNormalizer () {
        return this._paginationNormalizer
    }

    get personalInfoNormalizer () {
        return this._personalInfoNormalizer
    }

    get selectedFareMedia () {
        return this._selectedFareMedia
    }

    set selectedFareMedia (selectedFareMedia) {
        this._selectedFareMedia = selectedFareMedia
    }

    get selectedOrder () {
        return this._selectedOrder
    }

    set selectedOrder (selectedOrder) {
        this._selectedOrder = selectedOrder
    }

    get store () {
        return this._store
    }

    set store (store) {
        this._store = store
    }

    get wallet () {
        return this._wallet
    }

    set wallet (wallet) {
        this._wallet = wallet
    }

    getAllBankAccounts (state) {
        if (!isEmpty(state.bankAccounts)) {
            return state.bankAccounts
        }
        return []
    }

    getBankAccountsProvidersIdsCount (state) {
        let count = 0
        if (!isEmpty(state.bankAccounts)) {
            for (const i in state.bankAccounts) {
                if (!isEmpty(state.bankAccounts[i])) {
                    for (const j in state.bankAccounts[i]) {
                        if (!isEmpty(state.bankAccounts[i][j])) {
                            count++
                        }
                    }
                }
            }
        }
        return count
    }

    getAllDirectDebits (state) {
        if (!isEmpty(state.directDebits) && !isEmpty(state.directDebits.content)) {
            return state.directDebits.content
        }
        return []
    }

    getPastDirectDebitsByYear (state) {
        return this.getDirectDebitsByYear(state.pastDirectDebits)
    }

    getFutureDirectDebitsByYear (state) {
        return this.getDirectDebitsByYear(state.futureDirectDebits)
    }

    getDirectDebitsByYear (directDebits) {
        const years = []

        if (!isEmpty(directDebits) && !isEmpty(directDebits.content)) {
            const yearMap = {}

            directDebits.content.forEach(item => {
                const yearHeader = moment(item.date).format('YYYY')

                if (!yearMap[yearHeader]) {
                    const newYearGroup = {
                        id: yearHeader,
                        label: moment(item.date).format('YYYY').ucFirst(),
                        directDebits: []
                    }
                    yearMap[yearHeader] = newYearGroup
                    years.push(newYearGroup)
                }
                yearMap[yearHeader].directDebits.push(item)
            })
        }

        return years
    }

    getOrders (state) {
        if (!isEmpty(state.orders) && !isEmpty(state.orders.content)) {
            return state.orders.content
        }
        return []
    }

    getOrdersByYear (state) {
        if (!isEmpty(state.orders) && !isEmpty(state.orders.content)) {
            return state.orders.content.reduce((acc, item) => {
                const date = !isEmpty(item.purchaseDate) ? item.purchaseDate : item.creationDate
                const yearHeader = moment(date).format('YYYY')
                const yearIndex = acc.findIndex(year => year.id === yearHeader)

                if (yearIndex === -1) {
                    acc.push({
                        id: yearHeader,
                        label: yearHeader,
                        orders: [item]
                    })
                } else {
                    acc[yearIndex].orders.push(item)
                }

                return acc
            }, [])
        }

        return []
    }

    getUserStatus (state) {
        const wallet = this.getWallet(state)
        return wallet.userStatus
    }

    getWallet (state) {
        return !isEmpty(state.wallet) ? state.wallet : new Wallet()
    }

    getWalletProvidersWithFareMedia (state) {
        const wallet = this.getWallet(state)
        const providers = []
        for (const provider of wallet.providers) {
            const hasFareMedia = provider.accounts.some(account => account.fareMedias.length > 0)
            if (hasFareMedia) {
                providers.push(provider)
            }
        }
        return providers
    }

    getWalletProviders (state) {
        const wallet = this.getWallet(state)
        const providers = []
        wallet.providers.forEach(provider => {
            providers.push(provider)
        })
        return providers
    }

    getWalletProviderAccounts (state) {
        const wallet = this.getWallet(state)
        const providersAccounts = []
        wallet.providers.forEach(provider => {
            if (!isEmpty(provider.accounts)) {
                provider.accounts.forEach(account => {
                    providersAccounts.push(account)
                })
            }
        })

        return providersAccounts
    }

    getNonEmptyWalletProviderAccounts (state) {
        const wallet = this.getWallet(state)
        const providersAccounts = []
        wallet.providers.forEach(provider => {
            if (!isEmpty(provider.accounts)) {
                provider.accounts.forEach(account => {
                    if (!isEmpty(account.products)) {
                        providersAccounts.push(account)
                    }
                })
            }
        })

        return providersAccounts
    }

    getWalletProvidersAvailableForAdd (state) {
        const wallet = this.getWallet(state)
        const providersAvailableForAdd = []
        wallet.providers.forEach(provider => {
            if (provider.walletAddAccount) {
                providersAvailableForAdd.push(provider)
            }
        })

        return providersAvailableForAdd
    }

    getWalletFareMedias (state) {
        const wallet = this.getWallet(state)
        let walletFareMedias = []

        if (!isEmpty(wallet.providers)) {
            wallet.providers.forEach(walletProvider => {
                walletFareMedias = walletFareMedias.concat(this.getWalletFareMediasByProvider(walletProvider))
            })
        }
        if (!isEmpty(wallet.associatedAccounts)) {
            wallet.associatedAccounts.forEach(associatedAccount => {
                if (!isEmpty(associatedAccount.providers)) {
                    associatedAccount.providers.forEach(provider => {
                        walletFareMedias = walletFareMedias.concat(this.getWalletFareMediasByProvider(provider))
                    })
                }
            })
        }

        return walletFareMedias
    }

    getWalletFareMediasByProvider (provider) {
        if (!provider.accounts || !provider.accounts.length) {
            return []
        }

        return provider.accounts.flatMap(providerAccount => providerAccount.fareMedias ? providerAccount.fareMedias : [])
    }

    getWalletAllSupports (state) {
        const wallet = this.getWallet(state)
        const walletSupports = []
        if (!isEmpty(wallet.providers)) {
            wallet.providers.forEach(walletProvider => {
                if (!isEmpty(walletProvider.accounts)) {
                    walletProvider.accounts.forEach(providerAccount => {
                        if (!isEmpty(providerAccount.fareMedias)) {
                            providerAccount.fareMedias.forEach(fareMedia => {
                                walletSupports.push(fareMedia)
                            })
                        } else if (!isEmpty(providerAccount.devices)) {
                            providerAccount.devices.forEach(device => {
                                walletSupports.push(device)
                            })
                        } else {
                            const normalizeProviderAccount = {
                                providerId: providerAccount.provider,
                                accountId: providerAccount.id,
                                id: providerAccount.id,
                                isAccount: true,
                                recipientUser: providerAccount.recipientUser,
                                providerUserId: providerAccount.id
                            }
                            walletSupports.push(normalizeProviderAccount)
                        }
                    })
                }
            })
        }
        return walletSupports
    }

    getWalletFareProfiles (state) {
        const wallet = this.getWallet(state)
        const walletFareProfiles = []

        if (!isEmpty(wallet.providers)) {
            wallet.providers.forEach(walletProvider => {
                if (!isEmpty(walletProvider.accounts)) {
                    walletProvider.accounts.forEach(providerAccount => {
                        if (!isEmpty(providerAccount.fareProfiles)) {
                            providerAccount.fareProfiles.forEach(fareProfile => {
                                walletFareProfiles.push(fareProfile)
                            })
                        }
                    })
                }
            })
        }

        return walletFareProfiles
    }

    getWalletAssociatedFareMedias (state) {
        const associatedAccounts = this.getWalletAssociatedAccounts(state)
        const associatedFareMedias = []

        if (!isEmpty(associatedAccounts)) {
            associatedAccounts.forEach(associatedAccount => {
                associatedAccount.providers.forEach(walletProvider => {
                    if (!isEmpty(walletProvider.accounts)) {
                        walletProvider.accounts.forEach(providerAccount => {
                            if (!isEmpty(providerAccount.fareMedias)) {
                                providerAccount.fareMedias.forEach(fareMedia => {
                                    associatedFareMedias.push(fareMedia)
                                })
                            }
                        })
                    }
                })
            })
        }

        return associatedFareMedias
    }

    getWalletAssociatedProviderAccounts (state) {
        const associatedAccounts = this.getWalletAssociatedAccounts(state)

        if (isEmpty(associatedAccounts)) {
            return []
        }

        return associatedAccounts.flatMap(associatedAccount =>
            associatedAccount.providers.flatMap(walletProvider =>
                isEmpty(walletProvider.accounts) ? [] : walletProvider.accounts
            )
        )
    }

    getWalletPlans (state) {
        const wallet = this.getWallet(state)
        return wallet.plans
    }

    getWalletAssociatedAccounts (state) {
        const wallet = this.getWallet(state)
        return wallet.associatedAccounts
    }

    getWalletCounters (state) {
        const wallet = this.getWallet(state)
        return wallet.counters
    }

    getWalletSupports (state) {
        const wallet = this.getWallet(state)
        const walletSupports = []

        if (!isEmpty(wallet.providers)) {
            let item
            let i = 1
            wallet.providers.forEach(walletProvider => {
                if (!isEmpty(walletProvider.accounts)) {
                    walletProvider.accounts.forEach(providerAccount => {
                        item = {
                            account: providerAccount,
                            correspondingBasketInfos: ['PROVIDERACCOUNT'],
                            correspondingTargets: ['ACCOUNT'],
                            provider: walletProvider,
                            icon: 'Phone',
                            index: i,
                            title: '',
                            subtitle: ''
                        }
                        if (providerAccount.firstName === '' && providerAccount.lastName === '') {
                            item.title = i18n.global.t('sale_option:basket_info:provider_account:title').replace('%walletName%', providerAccount.walletName).replace('%accountId%', providerAccount.id)
                        } else {
                            item.title = i18n.global.t('sale_option:basket_info:provider_account:title_with_name').replace('%walletName%', providerAccount.walletName).replace('%fullName%', providerAccount.firstName.ucFirst() + ' ' + providerAccount.lastName.ucFirst())
                            item.subtitle = `n°${providerAccount.id}`
                        }

                        walletSupports.push(item)
                        i++
                        if (!isEmpty(providerAccount.fareMedias)) {
                            providerAccount.fareMedias.forEach(fareMedia => {
                                if (FareMedia.activeStatuses.includes(fareMedia.status.id)) {
                                    item = {
                                        account: providerAccount,
                                        correspondingBasketInfos: ['PROVIDERACCOUNTANDFAREMEDIA', 'PROVIDERFAREMEDIA'],
                                        correspondingTargets: ['CARD'],
                                        fareMedia,
                                        icon: 'Card',
                                        index: i,
                                        provider: walletProvider,
                                        title: fareMedia.getTitle(),
                                        subtitle: fareMedia.getSubtitle()
                                    }

                                    walletSupports.push(item)
                                    i++
                                }
                            })
                        }
                    })
                }
            })
        }

        return walletSupports
    }

    unlinkFareMedia (fareMedia) {
        if (!isEmpty(this.wallet) && !isEmpty(this.wallet.providers)) {
            for (const i in this.wallet.providers) {
                if (this.wallet.providers[i].id === fareMedia.providerId && !isEmpty(this.wallet.providers[i].accounts)) {
                    for (const j in this.wallet.providers[i].accounts) {
                        if (this.wallet.providers[i].accounts[j].id === fareMedia.accountId) {
                            this.wallet.providers[i].accounts.splice(j, 1)
                        }
                    }
                }
            }
            this.store.commit(mutationTypes.SET_WALLET, this.wallet)
        }
    }

    updateBankAccounts (providerId, providerUserId, bankAccounts) {
        if (isEmpty(this.bankAccounts)) {
            this.bankAccounts = {}
        }
        if (isEmpty(this.bankAccounts[providerId])) {
            this.bankAccounts[providerId] = {}
        }
        if (isEmpty(this.bankAccounts[providerId][providerUserId])) {
            this.bankAccounts[providerId][providerUserId] = []
        }
        this.bankAccounts[providerId][providerUserId] = bankAccounts
    }

    async enrichUserInfo (userInfos) {
        if (!LoginManager.userInfos) {
            await LoginManager.getUserInfosAction({
                callback: () => {
                    this.enrichUserInfo(userInfos)
                }
            })

            return
        }

        if (userInfos.birthDate) {
            LoginManager.userInfos.birthdate = userInfos.birthDate
        }
        if (userInfos.firstName) {
            LoginManager.userInfos.firstName = userInfos.firstName
        }
        if (userInfos.id) {
            LoginManager.userInfos.id = userInfos.id
        }
        if (userInfos.lastName) {
            LoginManager.userInfos.lastName = userInfos.lastName
        }
        if (userInfos.mobilePhoneNumber) {
            LoginManager.userInfos.phone = userInfos.mobilePhoneNumber
        } else if (userInfos.landlinePhoneNumber) {
            LoginManager.userInfos.phone = userInfos.landlinePhoneNumber
        }

        this.store.commit(`login/${loginMutationTypes.SET_USER_INFOS}`, LoginManager.userInfos)
        this.store.commit(mutationTypes.SET_USER_INFOS, LoginManager.userInfos)
    }

    addQueryParams (url, params) {
        const queryParams = new URLSearchParams()

        for (const i in params) {
            queryParams.append(i, params[i])
        }

        if (queryParams.size > 0) {
            url += `?${queryParams}`
        }

        return url
    }

    /* =============================================================================================================
    =================================================== ACTIONS ====================================================
    ============================================================================================================= */
    activateAccountAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'activateAccount', value: true })

        const cachiosParams = {}
        if (params.accessToken) {
            cachiosParams.headers = {
                Authorization: params.accessToken
            }
        }

        cachios
            .put(`${process.env.VUE_APP_API_URL}/account/activate`, {}, cachiosParams)
            .then(response => {
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'activateAccount', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
            .catch(error => {
                ErrorManager.displayErrorModal('user:activate_account', error)
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'activateAccount', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
    }

    deactivateAccountAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'deactivateAccount', value: true })
        cachios
            .put(`${process.env.VUE_APP_API_URL}/account/deactivate`)
            .then(response => {
                emitter.emit('logOut')

                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'deactivateAccount', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
            .catch(error => {
                ErrorManager.displayErrorModal('user:deactivate_account', error)
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'deactivateAccount', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
    }

    deleteAccountAction ({ isPersonalDataToDelete = false }) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'deleteAccount', value: true })

        return cachios
            .post(`${process.env.VUE_APP_API_URL}/account/deleteAccount?isPersonalDataToDelete=${isPersonalDataToDelete}`)
            .then(response => {
                this.store.commit(mainMutationTypes.SET_FEEDBACK_SUCCESS, i18n.global.t('account:personal_datas:delete_account:success'))
            })
            .catch(error => {
                ErrorManager.displayErrorModal('account:personal_datas:delete_account', error)
                return Promise.reject(error)
            })
            .finally(() => {
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'deleteAccount', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
    }

    userDataClaimAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'userDataClaim', value: true })
        cachios
            .post(`${process.env.VUE_APP_API_URL}/account/userDataClaim`)
            .then(response => {
                this.store.commit(mainMutationTypes.SET_FEEDBACK_SUCCESS, i18n.global.t('account:personal_datas:claim:success'))
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'userDataClaim', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
            .catch(error => {
                ErrorManager.displayErrorModal('account:personal_datas:retreive_my_infos', error)
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'userDataClaim', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
    }

    getConsentsAction () {
        return new Promise((resolve, reject) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getConsents', value: true })
            cachios
                .get(`${process.env.VUE_APP_API_URL}/account/consents`, {
                    force: true
                })
                .then(response => {
                    resolve(response.data)
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getConsents', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
                .catch(error => {
                    ErrorManager.displayErrorModal('user:get_consent', error)
                    reject(error.message)
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getConsents', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
        })
    }

    getCertificateAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getCertificate', value: true })
        cachios
            .get(`${process.env.VUE_APP_API_URL}/wallet/providers/${params.providerId}/accounts/${params.accountId}/products/${params.serialNumber}/certificate`, {
                responseType: 'blob'
            })
            .then(response => {
                const blob = new Blob([response.data], { type: response.headers['content-type'] ?? 'application/pdf' })
                const url = window.URL.createObjectURL(blob)

                window.open(url)
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getCertificate', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
            .catch(error => {
                ErrorManager.displayErrorModal('user:get_product_certificate', error)
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getCertificate', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
    }

    downloadFareMediaCertificate (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, {
            key: 'downloadFareMediaCertificate',
            value: true
        })

        const url = this.addQueryParams(`${process.env.VUE_APP_API_URL}/wallet/providers/${params.providerId}/accounts/fareMedias/${params.fareMediaId}/certificate`, {
            providerUserExternalId: params.providerUserExternalId,
            providerUserId: params.providerUserId
        })

        return cachios
            .get(url, {
                force: true,
                responseType: 'blob',
                params: {
                    startDate: params.startDate,
                    endDate: params.endDate
                }
            })
            .then(response => {
                const blob = new Blob([response.data], { type: response.headers['content-type'] ?? 'application/pdf' })
                const url = window.URL.createObjectURL(blob)
                window.open(url)

                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, {
                    key: 'downloadFareMediaCertificate',
                    value: true
                })
                this.store.commit(mutationTypes.SET_CERTIFICATE_DOWNLOAD_ERROR, null)
            })
            .catch(error => {
                const self = this

                // The api response is a blob when it is a success
                // but when there is an error it is a json.
                // However, with the mandatory "responseType: blob", cachios always returns a blob.
                // We have to do this code to transform the response into json
                const reader = new FileReader()

                return new Promise((resolve) => {
                    reader.onload = function () {
                        error.response.data = JSON.parse(reader.result)
                        if (error.response?.data?.status >= 400 && error.response?.data?.status < 500) {
                            self.store.commit(mutationTypes.SET_CERTIFICATE_DOWNLOAD_ERROR, error.response.data)
                        } else {
                            ErrorManager.displayErrorModal('user:download_fare_media_certificate', error)
                        }
                        self.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                        self.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, {
                            key: 'downloadFareMediaCertificate',
                            value: false
                        })

                        resolve()
                    }
                    reader.readAsText(error.response.data)
                })
            })
    }

    resetCertificateModalState () {
        this.store.commit(mutationTypes.SET_CERTIFICATE_DOWNLOAD_ERROR, null)
    }

    getDirectDebits (params) {
        const status = (params.endDate) ? 'past' : 'future'
        let requestKey = 'getFutureDirectDebits'
        let loadedKey = 'futureDirectDebitsLoaded'
        if (status === 'past') {
            requestKey = 'getPastDirectDebits'
            loadedKey = 'pastDirectDebitsLoaded'
        }
        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: requestKey, value: true })
        if (this.debuggingManager.getMockActionState(requestKey)) {
            this.debuggingManager.getResponse(requestKey, (response) => {
                const directDebits = this.paginationNormalizer.normalize(response.data, this.directDebitNormalizer)
                if (status === 'past') {
                    this.store.commit(mutationTypes.SET_PAST_DIRECT_DEBITS, directDebits)
                } else {
                    this.store.commit(mutationTypes.SET_FUTURE_DIRECT_DEBITS, directDebits)
                }
                emitter.emit(loadedKey)
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: requestKey, value: false })
            })
        } else {
            // TODO : Make it dynamic
            const body = {
                nbResultsPerPage: 5,
                reverseOrder: false
            }
            if (params.page) {
                body.pageNumber = params.page
            }
            if (params.nbResultsPerPage) {
                body.nbResultsPerPage = params.nbResultsPerPage
            }
            if (params.pageNumber) {
                body.pageNumber = params.pageNumber
            }
            if (params.providerId) {
                body.providerId = params.providerId
            }
            if (params.providerUserExternalId) {
                body.providerUserExternalId = params.providerUserExternalId
            }
            if (params.providerUserId) {
                body.providerUserId = params.providerUserId
            }
            if (params.startDate) {
                body.startDate = params.startDate
            }
            if (params.endDate) {
                body.endDate = params.endDate
                body.reverseOrder = true
            }

            cachios
                .post(`${process.env.VUE_APP_API_URL}/payment/schedulesPayments`, body)
                .then(response => {
                    const directDebits = this.paginationNormalizer.normalize(response.data, this.directDebitNormalizer)
                    const status = (params.endDate) ? 'past' : 'future'
                    if (status === 'past') {
                        this.store.commit(mutationTypes.SET_PAST_DIRECT_DEBITS, directDebits)
                    } else {
                        this.store.commit(mutationTypes.SET_FUTURE_DIRECT_DEBITS, directDebits)
                    }
                    emitter.emit(loadedKey)
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: requestKey, value: false })
                })
                .catch(error => {
                    ErrorManager.displayErrorModal('user:get_direct_debits', error)
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: requestKey, value: false })
                })
        }
    }

    getInvoiceAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getInvoice', value: true })
        cachios
            .get(`/orders/${params.orderId}/invoices/${params.invoiceId}`, {
                responseType: 'blob',
                force: true
            })
            .then(response => {
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getInvoice', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                const blob = new Blob([response.data], { type: response.headers['content-type'] ?? 'application/pdf' })
                const url = window.URL.createObjectURL(blob)

                window.open(url)
            })
            .catch(error => {
                ErrorManager.displayErrorModal('user:get_invoice', error)
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getInvoice', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
    }

    getNotificationsAction (params) {
        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getNotifications', value: true })
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })

        if (this.debuggingManager.getMockActionState('getNotifications')) {
            this.debuggingManager.getResponse('getNotifications', (response) => {
                this.notifications = NotificationNormalizer.normalizeNotificationList(this.notifications, response.data)
                this.store.commit(mutationTypes.SET_NOTIFICATIONS, this.notifications)
                emitter.emit('notificationsLoaded')
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getNotifications', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
        } else {
            cachios
                .get(`${process.env.VUE_APP_USER_URL}/notifications`)
                .then(response => {
                    this.notifications = NotificationNormalizer.normalizeNotificationList(this.notifications, response.data)
                    this.store.commit(mutationTypes.SET_NOTIFICATIONS, this.notifications)
                    emitter.emit('notificationsLoaded')
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getNotifications', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
                .catch(error => {
                    ErrorManager.displayErrorModal('user:get_notifications', error)
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getNotifications', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
        }
    }

    getOrderAction (params) {
        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getOrder', value: true })

        if (this.debuggingManager.getMockActionState('getOrder')) {
            this.debuggingManager.getResponse('getOrder', (response) => {
                if (!isEmpty(response.data)) {
                    this.order = this.orderNormalizer.normalize(response.data)
                    const selectedOrderOptions = { order: this.order, orderPage: params.orderPage }
                    this.store.commit(mutationTypes.SET_SELECTED_ORDER, selectedOrderOptions)
                    emitter.emit('orderLoaded', this.order)
                }

                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getOrder', value: false })
            })
        } else {
            cachios
                .get(`/orders/${params.orderId}`)
                .then(response => {
                    if (!isEmpty(response.data)) {
                        this.order = this.orderNormalizer.normalize(response.data)
                        const selectedOrderOptions = { order: this.order, orderPage: params.orderPage }
                        this.store.commit(mutationTypes.SET_SELECTED_ORDER, selectedOrderOptions)
                        emitter.emit('orderLoaded', this.order)
                    }

                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getOrder', value: false })
                })
                .catch(error => {
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getOrder', value: false })
                    let errorMessage = ''
                    if (error.response.status === 404) {
                        errorMessage = i18n.global.t('account:order_details:error:not_found')
                    }
                    emitter.emit('orderLoadedError', errorMessage)
                    ErrorManager.displayErrorModal('user:get_order', error)
                })
        }
    }

    getOrdersAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getOrders', value: true })
        if (this.debuggingManager.getMockActionState('getOrders')) {
            this.debuggingManager.getResponse('getOrders', (response) => {
                this.orders = this.paginationNormalizer.normalize(response.data, this.orderNormalizer)
                this.store.commit(mutationTypes.SET_ORDERS, this.orders)
                emitter.emit('ordersLoaded')
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getOrders', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
        } else {
            const url = `/orders?page=${params.page ?? 0}&size=${params.nbResultsPerPage ?? 10}`
            cachios
                .get(url)
                .then(response => {
                    this.orders = this.paginationNormalizer.normalize(response.data, this.orderNormalizer)
                    this.store.commit(mutationTypes.SET_ORDERS, this.orders)
                    emitter.emit('ordersLoaded')
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getOrders', value: false })
                })
                .catch(error => {
                    ErrorManager.displayErrorModal('user:get_orders', error)
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getOrders', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
        }
    }

    getPersonalInfoAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getPersonalInfo', value: true })

            if (this.debuggingManager.getMockActionState('getPersonalInfo')) {
                this.debuggingManager.getResponse('getPersonalInfo', (response) => {
                    this.store.commit(mutationTypes.SET_PERSONAL_INFO, this.personalInfoNormalizer.normalize(response.data))
                    emitter.emit('personalInfoLoaded')
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getPersonalInfo', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
            } else {
                cachios
                    .get(`${process.env.VUE_APP_API_URL}/account/information/list`, !isEmpty(params) && params?.cachiosOptions)
                    .then(response => {
                        this.store.commit(mutationTypes.SET_PERSONAL_INFO, this.personalInfoNormalizer.normalize(response.data))
                        if (config.userweb.kas_user_infos_enrichment_method === 'sytral') {
                            this.enrichUserInfo(response.data)
                        }
                        emitter.emit('personalInfoLoaded')
                        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getPersonalInfo', value: false })
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

                        resolve(response.data)
                    })
                    .catch(error => {
                        ErrorManager.displayErrorModal('user:get_personal_info', error)
                        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getPersonalInfo', value: false })
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

                        reject(error)
                    })
            }
        })
    }

    getWalletAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getWallet', value: true })
            if (this.debuggingManager.getMockActionState('getWallet')) {
                return this.debuggingManager.getResponse('getWallet', (response) => {
                    this.wallet = this.walletNormalizer.normalize(response.data)
                    this.store.commit(mutationTypes.SET_WALLET, this.wallet)
                    emitter.emit('walletLoaded')
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getWallet', value: false })
                    if (!isEmpty(params) && typeof params.callback === 'function') {
                        params.callback()
                    }

                    resolve(this.wallet)
                })
            } else {
                let url = `${process.env.VUE_APP_API_URL}/wallet`
                if (this.store.state.debuggingModule.forceUseWalletCache) {
                    url = url.setUrlParameter('cacheAsync', true)
                    url = url.setUrlParameter('cacheValidity', 15)
                } else if (!isEmpty(params) && !isEmpty(params.wsOptions)) {
                    if (typeof params.wsOptions.cacheAsync !== 'undefined') {
                        url = url.setUrlParameter('cacheAsync', params.wsOptions.cacheAsync)
                    }
                    if (typeof params.wsOptions.cacheValidity !== 'undefined') {
                        url = url.setUrlParameter('cacheValidity', params.wsOptions.cacheValidity)
                    }
                }
                return cachios
                    .get(url, !isEmpty(params) && typeof params.cachiosOptions !== 'undefined' ? params.cachiosOptions : {})
                    .then(response => {
                        this.wallet = this.walletNormalizer.normalize(response.data)
                        this.store.commit(mutationTypes.SET_WALLET, this.wallet)
                        emitter.emit('walletLoaded')
                        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getWallet', value: false })
                        if (!isEmpty(params) && typeof params.callback !== 'undefined' && typeof params.callback === 'function') {
                            params.callback()
                        }

                        resolve(this.wallet)
                    })
                    .catch(error => {
                        ErrorManager.displayErrorModal('user:get_wallet', error)
                        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getWallet', value: false })
                        reject(error)
                    })
            }
        })
    }

    linkFareMediaAction (params) {
        return new Promise((resolve, reject) => {
            const body = {}
            if (params.formType === 'FAREMEDIA_BIRTHDATE') {
                body.fareMediaId = params.data.fareMediaId
                body.birthDate = params.data.birthdate
            } else if (params.formType === 'FIRSTNAME_LASTNAME_BIRTHDATE') {
                body.firstName = params.data.firstname
                body.lastName = params.data.lastname
                body.birthDate = params.data.birthdate
            } else if (params.formType === 'EMAIL_PASSWORD') {
                body.email = params.data.email
                body.password = params.data.password
            }
            this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'linkFareMedia', value: true })
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            cachios
                .post(`${process.env.VUE_APP_API_URL}/wallet/providers/${params.providerId}/accounts/link`, body)
                .then(response => {
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'linkFareMedia', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    if (response.status === 200) {
                        this.store.dispatch(actionTypes.GET_WALLET, {
                            cachiosOptions: {
                                force: true
                            },
                            wsOptions: {
                                cacheAsync: false,
                                cacheValidity: 0
                            }
                        })
                    }
                    resolve(response.data)
                })
                .catch((error) => {
                    ErrorManager.displayErrorModal('user:link_fare_media', error)
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'linkFareMedia', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    reject(error)
                })
        })
    }

    async setEmailAction (params) {
        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setEmail', value: true })

        return cachios
            .put(`${process.env.VUE_APP_API_URL}/account/email/update`, { email: params.newEmail }, {
                force: true
            })
            .then(response => {
                emitter.emit('emailUpdated')
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setEmail', value: false })

                return {
                    type: 'email',
                    oldEmail: params.oldEmail,
                    newEmail: params.newEmail,
                    success: true
                }
            })
            .catch(error => {
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setEmail', value: false })

                return {
                    success: false,
                    reason: i18n.global.t(`user:set_email:error:${error.response.status}:message`)
                }
            })
    }

    setNotificationAsReadAction (params) {
        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setNotificationAsRead', value: true })

        cachios
            .post(`${process.env.VUE_APP_USER_URL}/notifications/${params.id}`)
            .then(response => {
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setNotificationAsRead', value: false })

                this.store.commit(mutationTypes.SET_NOTIFICATIONS, NotificationNormalizer.markNotificationAsRead(this.notifications, params.id))
            })
            .catch(error => {
                ErrorManager.displayErrorModal('user:set_notification_as_read', error)
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setNotificationAsRead', value: false })
            })
    }

    async setPersonalInfoAction (params) {
        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setPersonalInfo', value: true })

        return cachios
            .put(`${process.env.VUE_APP_API_URL}/account/information/update`, params, {
                force: true
            })
            .then(async (response) => {
                emitter.emit('personalInfoUpdated')

                await this.store.dispatch(addressActionTypes.GET_ADDRESSES, {
                    cachiosOptions: {
                        force: true
                    }
                })

                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setPersonalInfo', value: false })

                return {
                    type: 'civility',
                    success: true
                }
            })
            .catch(error => {
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setPersonalInfo', value: false })

                return {
                    success: false,
                    reason: i18n.global.t(`user:set_personal_info:error:${error.response.status}:message`)
                }
            })
    }

    async setPhoneAction (params) {
        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setPhone', value: true })

        return cachios
            .put(`${process.env.VUE_APP_API_URL}/account/phone/update`, params, {
                force: true
            })
            .then(response => {
                emitter.emit('phoneUpdated')

                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setPhone', value: false })

                return {
                    type: 'phone',
                    success: true
                }
            })
            .catch(error => {
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setPhone', value: false })

                return {
                    success: false,
                    reason: i18n.global.t(`user:set_phone:error:${error.response.status}:message`)
                }
            })
    }

    terminatePlanAction (params) {
        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'terminatePlan', value: true })
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })

        if (this.debuggingManager.getMockActionState('terminatePlan')) {
            this.debuggingManager.getResponse('terminatePlan', (response) => {
                if (!isEmpty(this.wallet) && !isEmpty(this.wallet.userPlans)) {
                    for (const i in this.wallet.userPlans) {
                        if (this.wallet.userPlans[i].planId === params.id) {
                            this.wallet.userPlans.splice(i, 1)
                        }
                    }
                    this.store.commit(mutationTypes.SET_WALLET, this.wallet)
                }
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'terminatePlan', value: false })
            })
        } else {
            cachios
                .delete(`${process.env.VUE_APP_API_URL}/wallet/modalPlans/${params.id}`)
                .then(response => {
                    if (response.status === 200) {
                        if (!isEmpty(this.wallet) && !isEmpty(this.wallet.userPlans)) {
                            for (const i in this.wallet.userPlans) {
                                if (this.wallet.userPlans[i].planId === params.id) {
                                    this.wallet.userPlans.splice(i, 1)
                                }
                            }
                            this.store.commit(mutationTypes.SET_WALLET, this.wallet)
                        }
                    }
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'terminatePlan', value: false })
                })
                .catch(error => {
                    ErrorManager.displayErrorModal('user:terminate_plan', error)
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'terminatePlan', value: false })
                })
        }
    }

    updatePersonalInfoAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'updatePersonalInfo', value: true })

        cachios
            .put(`${process.env.VUE_APP_USER_URL}/userinfo/update`, { attributes: params.attributes }, {
                force: true
            })
            .then(response => {
                if (!params.silent) {
                    this.store.commit(mainMutationTypes.SET_FEEDBACK_SUCCESS, i18n.global.t('personal_info:edit:success'))
                }

                emitter.emit('personalInfoUpdated')

                if (config.use_web_harmony) {
                    document.body.dispatchEvent(new CustomEvent('kas-get-user-infos'))
                } else {
                    this.store.dispatch(`login/${loginActionTypes.GET_USER_INFOS}`, {
                        cachiosOptions: {
                            force: true
                        }
                    })
                }

                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'updatePersonalInfo', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
            .catch(error => {
                if (!params.silent) {
                    ErrorManager.displayErrorModal('user:update_personal_info', error)
                }
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'updatePersonalInfo', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
    }

    unlinkFareMediaAction (params) {
        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'unlinkFareMedia', value: true })

        if (this.debuggingManager.getMockActionState('unlinkFareMedia')) {
            this.debuggingManager.getResponse('unlinkFareMedia', (response) => {
                this.unlinkFareMedia(params.fareMedia)
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'unlinkFareMedia', value: false })
            })
        } else {
            cachios
                .delete(`${process.env.VUE_APP_API_URL}/wallet/providers/${params.fareMedia.providerId}/accounts/${params.fareMedia.accountId}`)
                .then(response => {
                    if (response.status === 200) {
                        this.unlinkFareMedia(params.fareMedia)
                    }
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'unlinkFareMedia', value: false })
                })
                .catch(error => {
                    ErrorManager.displayErrorModal('user:unlink_fare_media', error)
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'unlinkFareMedia', value: false })
                })
        }
    }

    setNewSubscriptionAccountAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setSubscription', value: true })
        cachios
            .post(`${process.env.VUE_APP_API_URL}/account/subscription`, params, {
                force: true
            })
            .then((response) => {
                this.store.dispatch(actionTypes.GET_WALLET, {
                    cachiosOptions: {
                        force: true
                    },
                    wsOptions: {
                        cacheAsync: false,
                        cacheValidity: 0
                    }
                })
                emitter.emit('getSubscriptionAccountSuccess')
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setSubscription', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
            .catch((error) => {
                emitter.emit('getSubscriptionAccountError')
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setSubscription', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                ErrorManager.displayErrorModal('user:set_new_subscription_account', error)
            })
    }

    setNewThirdAccountAction (params) {
        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
        this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setSubscription', value: true })
        cachios
            .post(`${process.env.VUE_APP_API_URL}/account/createThirdAccount`, {
                civility: params.form.civility,
                firstName: params.form.firstName,
                lastName: params.form.lastName,
                birthDate: params.form.birthDate + 'T00:00:00',
                birthPlace: params.form.birthPlace,
                email: params.form.email,
                mobilePhoneNumber: params.form.mobilePhoneNumber,
                landlinePhoneNumber: params.form.landlinePhoneNumber,
                communicationPreference: params.form.communicationPreference
            }, {
                force: true
            })
            .then(() => {
                emitter.emit('getSubscriptionThirdAccountSuccess')
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setSubscription', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
            .catch((error) => {
                ErrorManager.displayErrorModal('user:set_new_third_party_subscription_account', error)
                emitter.emit('getSubscriptionThirdAccountError')
                this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setSubscription', value: false })
                this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
            })
    }

    getNewProfileAccountAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getNewProfileAccount', value: true })

            const body = {
                providerUserExternalId: params.providerUserExternalId,
                providerUserId: params.providerUserId,
                recipientUser: params.recipientUser ?? null
            }

            cachios
                .post(`${process.env.VUE_APP_API_URL}/wallet/providers/${params.providerId}/accounts/fareProfiles`, body)
                .then((res) => {
                    resolve(res.data)
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getNewProfileAccount', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
                .catch((error) => {
                    ErrorManager.displayErrorModal('user:set_new_profile_account', error)
                    reject(error)
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'getNewProfileAccount', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
        })
    }

    setNewProfileAccountAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setNewProfileAccount', value: true })

            const body = {
                documents: params.documents,
                id: params.id,
                providerUserExternalId: params.providerUserExternalId,
                providerUserId: params.providerUserId,
                recipientUser: params.recipientUser ?? null
            }

            cachios
                .post(`${process.env.VUE_APP_API_URL}/wallet/providers/${params.providerId}/accounts/fareProfile`, body, { force: true })
                .then((res) => {
                    resolve(res.data)
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setNewProfileAccount', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
                .catch((error) => {
                    ErrorManager.displayErrorModal('user:get_new_profile_account', error)
                    reject(error)
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'setNewProfileAccount', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
        })
    }

    createProviderAccountAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'createProviderAccount', value: true })
            const values = params.values
            cachios
                .post(`${process.env.VUE_APP_API_URL}/wallet/providers/${params.providerId}/accounts`, values, { force: true })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    ErrorManager.displayErrorModal('user:create_provider_account', error)
                })
                .finally(() => {
                    this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'createProviderAccount', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
        })
    }

    executeActionOnWalletContract (body) {
        const setLoaderAndPendingState = (isLoading, isPending) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: isLoading })
            this.store.commit(mutationTypes.SET_USER_PENDING_REQUEST, { key: 'executeActionOnWalletContract', value: isPending })
        }

        return new Promise((resolve, reject) => {
            setLoaderAndPendingState(true, true)
            const type = body.actionType.toLowerCase()

            cachios.post(`${process.env.VUE_APP_API_URL}/wallet/contract`, body)
                .then(response => {
                    setLoaderAndPendingState(false, false)
                    resolve(response.data)
                })
                .catch(error => {
                    setLoaderAndPendingState(false, false)
                    ErrorManager.displayErrorModal(`wallet:contract:action:${type}`, error.errorMessage ?? error, error)
                    reject(error)
                })
        })
    }
}
