<template>
    <div class="RecurringSepaPayment">
        <h2 class="RecurringPayment-title">
            {{ $t('bank_account_information') }}
        </h2>
        <template v-if="!isEmptyBankAccount">
            <ul class="RecurringSepaPayment-list">
                <li
                    v-for="(item, index) in filteredBankAccounts"
                    :key="'bank-account-list_' + index"
                    :class="isSelectedBankAccount(item) ? 'RecurringSepaPayment-listItem--checked' : ''"
                    class="RecurringSepaPayment-listItem"
                    @click.prevent="updateValue(item)"
                >
                    <label
                        :for="item.providerBankAccountIdentifier"
                        class="RecurringSepaPayment-listItemLabel"
                    >
                        <span class="RecurringSepaPayment-listItemInputWrapper inputWrapper">
                            <input
                                :id="item.providerBankAccountIdentifier"
                                v-model="selectedBankAccount"
                                :aria-checked="isSelectedBankAccount(item)"
                                :value="item.activeMandate.uniqueMandateReference"
                                class="RecurringSepaPayment-listItemInput"
                                role="radio"
                                tabindex="0"
                                type="radio"
                            >
                        </span>
                        <span class="IconBank--outlined RecurringSepaPayment-listItemIcon" />
                        <span class="RecurringSepaPayment-listItemInfo">
                            <span class="RecurringSepaPayment-listItemIban">
                                <span class="RecurringSepaPayment-listItemIbanTitle">{{ $t('IBAN') }}</span>
                                <span class="RecurringSepaPayment-listItemIbanBank">{{ item.bankName }}</span>
                                <span class="RecurringSepaPayment-listItemIbanNumber">{{ item.iban }}</span>
                            </span>
                            <span class="RecurringSepaPayment-listItemOwner">
                                <span class="RecurringSepaPayment-listItemOwnerTitle">{{ $t('Owner_name') }}</span>
                                <span class="RecurringSepaPayment-listItemOwnerInfo">
                                    {{ currentProviderFullName }}
                                </span>
                            </span>
                        </span>
                    </label>
                </li>
            </ul>
            <v-btn
                class="bg-primary"
                size="small"
                @click.prevent="submitPayment"
            >
                {{ buttonLabel }}
            </v-btn>
        </template>
        <router-link
            :to="{ name: 'paymentMeans' }"
            class="RecurringSepaPayment-addBankAccount"
        >
            {{ $t('button:create_bank_account') }}
        </router-link>
    </div>
</template>

<script setup>
import * as paymentActionTypes from '@/StoreWeb/store/modules/payment/action-types'
import { isEmpty } from 'global-utils'
import { useStore } from 'vuex'
import emitter from 'global-emitter'
import * as cartActionTypes from '@/StoreWeb/store/modules/cart/action-types'
import AddressNormalizer from '@/StoreWeb/normalizers/address/AddressNormalizerSingleton'
import ErrorManager from '@/StoreWeb/managers/ErrorManager'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { getCartConfirmPayload } from '@/StoreWeb/js/composables/cart-utils'

const props = defineProps({
    buttonLabel: {
        type: String,
        default: 'confirm_order_sepa_payment_button'
    },
    selectedPaymentMode: {
        type: String,
        required: true
    }
})

const store = useStore()

const filteredBankAccounts = ref([])
const currentProvider = ref(null)
const currentProviderUserExternalId = ref(null)
const currentProviderUserId = ref(null)
const selectedBankAccount = ref(null)
const currentProviderFullName = ref(null)

const getWalletProviderAccounts = computed(() => store.getters.getWalletProviderAccounts)
const bankAccounts = computed(() => store.state.bankAccounts)
const cart = computed(() => store.state.cart)
const getCart = computed(() => store.getters.getCart)
const isEmptyBankAccount = computed(() => isEmpty(filteredBankAccounts.value))
const addressNormalizer = computed(() => AddressNormalizer.getInstance())

onMounted(() => {
    emitter.on('walletLoaded', getBankAccounts())
    emitter.on('getBankAccountsSuccess', () => {
        setFilteredBankAccounts()
        setDefaultSelectedBankAccount()
    })

    if (!isEmpty(getWalletProviderAccounts.value) && isEmpty(filteredBankAccounts.value)) {
        getBankAccounts()
    }
})

onUnmounted(() => {
    emitter.off('walletLoaded', getBankAccounts())
    emitter.off('getBankAccountsSuccess', () => {
        setFilteredBankAccounts()
        setDefaultSelectedBankAccount()
    })
})

const getBankAccounts = () => {
    setProviderAccountsInfos()
    if (currentProvider.value && currentProviderUserId.value && currentProviderUserExternalId.value) {
        store.dispatch(paymentActionTypes.GET_BANK_ACCOUNTS, {
            providerId: currentProvider.value,
            providerUserExternalId: currentProviderUserExternalId.value,
            providerUserId: currentProviderUserId.value,
            eligibleForPayment: true
        })
    }
}

const setFilteredBankAccounts = () => {
    if (!isEmpty(currentProvider.value) && !isEmpty(currentProviderUserId.value) && !isEmpty(bankAccounts.value)) {
        filteredBankAccounts.value = []
        bankAccounts[currentProvider.value][currentProviderUserId.value].forEach(bank => {
            if (!isEmpty(bank.mandates)) {
                let mandate = null
                bank.mandates.forEach(value => {
                    if (isEmpty(mandate) && value.status === 'A') {
                        mandate = value
                    }
                })
                if (!isEmpty(mandate)) {
                    bank.activeMandate = mandate
                    filteredBankAccounts.value.push(bank)
                }
            }
        })
    }
}

const setProviderAccountsInfos = () => {
    if (!isEmpty(getWalletProviderAccounts)) {
        const currentProviderAccount = getWalletProviderAccounts.value[0]
        currentProvider.value = currentProviderAccount.provider
        currentProviderUserId.value = currentProviderAccount.id
        currentProviderUserExternalId.value = currentProviderAccount.providerUserExternalId
        currentProviderFullName.value = currentProviderAccount.getFullName()
    }
}
const setDefaultSelectedBankAccount = () => {
    if (!isEmpty(filteredBankAccounts.value)) {
        selectedBankAccount.value = filteredBankAccounts.value[0].activeMandate.uniqueMandateReference
    }
}

const isSelectedBankAccount = (mandate) => {
    return selectedBankAccount.value === mandate.activeMandate.uniqueMandateReference
}

const updateValue = (newValue) => {
    if (newValue !== selectedBankAccount.value) {
        selectedBankAccount.value = newValue.activeMandate.uniqueMandateReference
    }
}

const submitPayment = async () => {
    try {
        const paymentInitPayload = {
            paymentInfos: {
                paymentMode: props.selectedPaymentMode,
                amount: cart.value.totalAmountIncludingTaxes,
                uniqueMandateReference: selectedBankAccount.value
            }
        }
        const cartConfirmPayload = getCartConfirmPayload(normalizeAddressFields)

        if (!cartConfirmPayload) {
            return
        }

        if (['PROVIDERS_TOTAL_CONFIRMED', 'PROVIDERS_TOTAL_CREATED'].includes(getCart.value.status)) {
            await store.dispatch(cartActionTypes.CART_CONFIRMATION, { ...cartConfirmPayload, ...paymentInitPayload })
        }

        await store.dispatch(paymentActionTypes.PAYMENT_INIT, paymentInitPayload)
    } catch (error) {
        ErrorManager.displayErrorModal('cart:get_cart_confirmation', error)
    }
}

const normalizeAddressFields = (payload) => {
    const fields = ['billingAddress', 'deliveryAddress', 'deliveryClickAndCollectAddress']
    fields.forEach(field => {
        if (payload[field]) {
            payload[field] = addressNormalizer.value.normalize(payload[field])
        }
    })
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.RecurringSepaPayment {
    margin: 0;
    text-align: center;

    &-title {
        @extend %h2;

        margin-bottom: $margin-small;
        text-align: center;
    }

    &-list {
        width: 100%;
        margin: 0 0 $margin-medium 0;
        padding: 0;
        list-style: none;
        text-align: left;
    }

    &-listItem {
        padding: $padding-small 0 $padding-small 0;
        border-bottom: 1px solid $color-lightgray2;

        &:last-child {
            border: 0;
        }
    }

    &-listItemIban {
        flex: 1 1 0;
    }

    &-listItemOwner {
        flex: 1 1 0;
    }

    &-listItemLabel {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: $color-lighterText;

        @media (max-width: $desktop-breakpoint) {
            justify-content: flex-start;
        }
    }

    &-listItemInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1 1;
        padding: $padding-xxsmall $padding-xxsmall;

        @media (max-width: $tablet-breakpoint) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &-listItemIcon {
        font-size: $font-xxlarge;
    }

    &-listItemIbanTitle,
    &-listItemOwnerTitle,
    &-listItemIbanBank {
        display: block;
        padding-bottom: $padding-xxxsmall;
        font-weight: bold;
    }

    &-listItemOwnerTitle {
        @media (max-width: $tablet-breakpoint) {
            padding-top: $padding-xsmall;
        }
    }

    &-listItemIbanTitle,
    &-listItemIbanNumber {
        font-size: $font-xxsmall;
    }

    &-listItemInput {
        position: absolute;
        top: -9999px;
        left: -9999px;
        width: 0;
        height: 0;
    }

    &-listItemInputWrapper {
        position: relative;
        overflow: hidden;
        width: 17px;
        height: 17px;
        margin-right: 5px;
        border: solid 1px $color-lightgray3;
        border-radius: 17px;
        transition: border-color .2s;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 6px;
            height: 6px;
            margin-top: -3px;
            margin-left: -3px;
            border-radius: 5px;
            background: $color-brandPrimary;
            opacity: 0;
            transition: opacity .2s;
        }
    }

    &-listItem--checked {
        .inputWrapper {
            border-color: $color-brandPrimary;

            &::after {
                opacity: 1;
            }
        }
    }

    &-confirmButton {
        margin: auto;
    }

    &-addBankAccount {
        display: inline-block;
        margin: $margin-small;
        text-decoration: underline;
    }
}
</style>
