export default class {
    constructor (id) {
        this._displayMode = 'default'
        this._filters = []
        this._icon = null
        this._id = id
        this._isMultiple = false
        this._name = null
        this._products = []
        this._search = ''
        this._size = 0
        this._value = null
    }

    get displayMode () {
        return this._displayMode
    }

    set displayMode (displayMode) {
        this._displayMode = displayMode
    }

    get filters () {
        return this._filters
    }

    set filters (filters) {
        this._filters = filters
    }

    get icon () {
        return this._icon
    }

    set icon (icon) {
        this._icon = icon
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get isMultiple () {
        return this._isMultiple
    }

    set isMultiple (isMultiple) {
        this._isMultiple = isMultiple
    }

    get name () {
        return this._name
    }

    set name (name) {
        this._name = name
    }

    get products () {
        return this._products
    }

    set products (products) {
        this._products = products
    }

    get search () {
        return this._search
    }

    set search (search) {
        this._search = search
    }

    get size () {
        return this._size
    }

    set size (size) {
        this._size = size
    }

    get value () {
        return this._value
    }

    set value (value) {
        this._value = value
    }

    setDefaultValue () {
        if (this.isMultiple) {
            this.value = []
        } else {
            this.value = ''
        }
    }

    toJSON () {
        return {
            displayMode: this.displayMode,
            filters: this.filters,
            icon: this.icon,
            id: this.id,
            isMultiple: this.isMultiple,
            name: this.name,
            products: this.products,
            search: this.search,
            size: this.size,
            value: this.value
        }
    }
}
