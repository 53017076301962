<template>
    <div class="ProductModal">
        <modal
            v-model="showModal"
            :title="getModalTitle"
            @close-modal="closeModal"
        >
            <div class="ProductModal-body">
                <product-add-to-cart-flow
                    v-if="getShowProductAddToCartFlow"
                    @add-to-cart="closeModal"
                    @ask-required-fare-profiles="askRequiredFareProfiles"
                    @login-to-ask-fare-profiles="loginToAskFareProfiles"
                />
                <product-required-fare-profiles
                    v-else-if="showRequiredFareProfilesModal"
                    :required-fare-profiles="requiredFareProfiles"
                    :selected-fare-media="selectedFareMedia"
                />
                <product-login-required-to-ask-fare-profiles
                    v-else-if="showLoginRequiredFareProfilesModal"
                    :required-fare-profiles="requiredFareProfiles"
                />
                <div
                    v-if="showWalletLoader"
                    class="text-center py-5"
                >
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    />
                </div>
            </div>
        </modal>
    </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { isEmpty } from 'global-utils'
import emitter from 'global-emitter'
import { useI18n } from 'vue-i18n'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import CatalogProduct from '@/StoreWeb/models/catalog/CatalogProduct'
import ProductAddToCartFlow from '@/StoreWeb/components/product/ProductAddToCartFlow'
import ProductLoginRequiredToAskFareProfiles from '@/StoreWeb/components/product/ProductLoginRequiredToAskFareProfiles'
import ProductRequiredFareProfiles from '@/StoreWeb/components/product/ProductRequiredFareProfiles'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'

const props = defineProps({
    context: {
        type: Object,
        default: null
    }
})

const store = useStore()
const { t } = useI18n()
const addToCartFlowManager = ref(AddToCartFlowManager.getInstance())
const { isAuthenticated } = useLogin()

const product = ref(new CatalogProduct(0, ''))
const showModal = ref(false)
const showRequiredFareProfilesModal = ref(false)
const showLoginRequiredFareProfilesModal = ref(false)
const requiredFareProfiles = ref(null)
const selectedFareMedia = ref(null)
const waitingAddToCartFlowRestoration = ref(null)
computed(() => store.state.cartModule.addToCartFlowStep)

const getUserStatus = computed(() => store.getters.getUserStatus)
const userPendingRequests = computed(() => store.state.userModule.userPendingRequests)
const wallet = computed(() => store.state.userModule.wallet)

const showWalletLoader = computed(() => userPendingRequests.value.getWallet)

const getModalTitle = computed(() => {
    if (showRequiredFareProfilesModal.value || showLoginRequiredFareProfilesModal.value) {
        return t('product:unable:add_to_cart')
    }

    return product.value.name
})

const getShowProductAddToCartFlow = computed(() => {
    if (showRequiredFareProfilesModal.value || showLoginRequiredFareProfilesModal.value) {
        return false
    }
    if (!isEmpty(product.value.getUserAuthModes())) {
        if (product.value.getUserAuthModes().includes('EMAILMANDATORY')) {
            return true
        }
    }
    return !showWalletLoader.value && getUserStatus.value === 'ACTIVE'
})

onMounted(() => {
    emitter.on('walletLoaded', onWalletLoaded)
    emitter.on('continueAddToCartFlowWithAnotherSupport', continueAddToCartProcessWithAnotherSupportHandler)
    emitter.on('continueAddToCartProcess', continueAddToCartProcessHandler)
    emitter.on('hideProductModal', closeModal)
    emitter.on('productsCatalogLoaded', productsCatalogLoadedHandler)
    emitter.on('productLoaded', productsCatalogLoadedHandler)
    emitter.on('showProductModal', showProductModalHandler)
    // emitter.on('saveProductToPutOnCard', saveProductToPutOnCard)
})

onUnmounted(() => {
    emitter.off('walletLoaded', onWalletLoaded)
    emitter.off('continueAddToCartFlowWithAnotherSupport', continueAddToCartProcessWithAnotherSupportHandler)
    emitter.off('continueAddToCartProcess', continueAddToCartProcessHandler)
    emitter.off('hideProductModal', closeModal)
    emitter.off('productsCatalogLoaded', productsCatalogLoadedHandler)
    emitter.off('productLoaded', productsCatalogLoadedHandler)
    emitter.off('showProductModal', showProductModalHandler)
    // emitter.off('saveProductToPutOnCard', saveProductToPutOnCard)
})

function productsCatalogLoadedHandler () {
    if (!isAuthenticated.value || (isAuthenticated.value && !showWalletLoader.value)) {
        addToCartFlowManager.value.checkPendingAddToCartFlow({})

        return
    }

    waitingAddToCartFlowRestoration.value = {
        context: {}
    }
}

function closeModal (keep = false) {
    if (!keep) {
        addToCartFlowManager.value.resetAddCartFlow()
    }

    showModal.value = false
    showRequiredFareProfilesModal.value = false
    showLoginRequiredFareProfilesModal.value = false
}

function continueAddToCartProcessHandler (productData) {
    product.value = productData
    openModal()
}

function continueAddToCartProcessWithAnotherSupportHandler (context) {
    const mergedContext = { ...props.context, ...context }

    if (!isAuthenticated.value || !showWalletLoader.value) {
        addToCartFlowManager.value.checkPendingAddToCartFlow({ context: mergedContext })

        return
    }

    waitingAddToCartFlowRestoration.value = { context: mergedContext }
}

function openModal () {
    showModal.value = true
}

function showProductModalHandler (productData) {
    product.value = productData
    addToCartFlowManager.value.initAddCartFlow(productData, props.context)
    openModal()
}

/* function saveProductToPutOnCard () {
    store.commit(mutationTypes.SET_PRODUCT_TO_PUT_ON_CARD, product.value)
} */

function askRequiredFareProfiles (data) {
    showRequiredFareProfilesModal.value = true
    requiredFareProfiles.value = data.requiredFareProfiles
    selectedFareMedia.value = data.selectedFareMedia
}

function loginToAskFareProfiles (data) {
    showLoginRequiredFareProfilesModal.value = true
    requiredFareProfiles.value = data.requiredFareProfiles
}

function onWalletLoaded () {
    if (showModal.value && getUserStatus.value !== 'ACTIVE') {
        closeModal()
        emitter.emit('showAlertModal', {
            messageHTML: t('wallet:blocked_payment_required:message'),
            title: t('wallet:blocked_payment_required:title')
        })
    }
}

watch(() => wallet.value, () => {
    if (isEmpty(waitingAddToCartFlowRestoration.value)) {
        return
    }

    addToCartFlowManager.value.checkPendingAddToCartFlow(waitingAddToCartFlowRestoration.value)
    waitingAddToCartFlowRestoration.value = null
})
</script>
