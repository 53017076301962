<template>
    <div
        :class="[(getStyle !== '' ? `ProductList--${getStyle}` : '')]"
        class="ProductList"
    >
        <component
            :is="getTitleTag"
            :class="'ProductList-title--' + getTitleTag"
            class="ProductList-title"
        >
            {{ name }}
        </component>
        <div
            v-if="products.length > 0"
            class="ProductList-products"
        >
            <product-item
                v-for="(product, productIndex) in products"
                :key="product.id"
                :index="productIndex"
                :product="product"
                :sectionStyle="getStyle"
                :level="level+1"
                :link-to-product-page="linkToProductPage"
                class="ProductList-item"
            />
        </div>
        <product-list
            v-for="(section) in getSections"
            v-bind="section"
            :key="section.id"
        />
    </div>
</template>

<script>
import ProductItem from './ProductItem'
import ProductList from './ProductList'
import { isEmpty } from 'global-utils'

export default {
    name: 'ProductList',
    components: {
        ProductItem,
        ProductList
    },
    props: {
        level: {
            type: Number,
            default: 1
        },
        name: {
            type: String,
            required: true
        },
        products: {
            type: Array,
            required: true
        },
        sections: {
            type: Array,
            default: null
        },
        sectionStyle: {
            type: String,
            default: ''
        },
        linkToProductPage: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        getSections () {
            return !isEmpty(this.sections) ? this.sections : []
        },
        getStyle () {
            return this.sectionStyle.toLowerCase()
        },
        getTitleTag () {
            if (this.level === 1) {
                return 'h2'
            } else if (this.level === 2) {
                return 'h3'
            }
            return 'h4'
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.ProductList {
    &-products {
        display: flex;
        position: relative;
        overflow-x: auto;
        padding: $s5 0 $s7;

        &::-webkit-scrollbar {
            display: none;
        }

        &::before {
            content: '';
            display: none;
            position: absolute;
            bottom: 50px;
            left: 5px;
            width: 119px;
            height: 93px;
            border-radius: 0 93px 0 0;
            background-color: $color-lighterText;

            @media #{map-get($display-breakpoints, 'sm-and-up')} {
                display: block;
            }
        }

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
            display: grid;
            grid-template-columns: repeat(2, calc(50% - $s4 / 2));
            gap: $s4;
            overflow: visible;
            padding: $s5 $s4 60px;
        }

        @media #{map-get($display-breakpoints, 'md-and-up')} {
            grid-template-columns: repeat(3, calc(100% / 3 - 30px / 3));
        }
    }

    &-showAll {
        padding-right: $s4;
        font-size: 16px;
        font-weight: $fontWeight-defaultBold;
        text-transform: uppercase;

        &Link {
            cursor: pointer;
            color: $color-brandPrimary;
        }
    }

    &-title {
        @extend %h2;

        flex-grow: 1;
        position: relative;
        margin-bottom: $s4;
        padding: 0 $s4;

        // Filter used on phone / tablet
        padding-right: calc(48px + $s4 * 2);

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
            font-size: 32px;
        }

        &--h3 {
            @extend %h3;

            @media #{map-get($display-breakpoints, 'sm-and-up')} {
                font-size: 24px;
            }
        }

        &--h4 {
            @extend %h4;

            @media #{map-get($display-breakpoints, 'sm-and-up')} {
                font-size: 18px;
            }
        }

        &::after {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 20px;
            width: 30px;
            height: 4px;
            background: $color-brandPrimary;
        }

        & + .ProductList {
            margin-top: 25px;
        }
    }

    &-item {
        min-width: 300px;
        max-width: 300px;
        min-height: 110px;
        margin-left: $s4;

        &:last-child {
            margin-right: $s4;

            @media #{map-get($display-breakpoints, 'sm-and-up')} {
                margin: 0;
            }
        }

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
            min-width: 0;
            max-width: none;
            margin: 0;
        }
    }

    &--highlighted {
        .ProductList-products {
            &::before {
                display: none;
            }

            @media #{map-get($display-breakpoints, 'md-and-up')} {
                grid-template-columns: repeat(3, calc(100% / 3 - 45px / 4));
            }
        }

        .ProductList-item {
            @media #{map-get($display-breakpoints, 'md-only')} {
                min-width: 300px;
                max-width: none;
            }

            @media #{map-get($display-breakpoints, 'md-and-up')} {
                min-width: 0;
                max-width: none;
            }
        }
    }
}
</style>
