import CatalogFilter from '@/StoreWeb/models/catalog/CatalogFilter'
import { isEmpty } from 'global-utils'
import config from 'config'

export default class {
    normalize (item) {
        const filter = new CatalogFilter(item.id)

        filter.displayMode = this.getDisplayModeFromId(item.id)
        filter.icon = this.getIconFromId(item.id)
        if (['MULTI_SELECT'].includes(filter.displayMode)) {
            filter.isMultiple = true
        }
        if (!isEmpty(item.name)) {
            filter.name = item.name
        }
        if (!isEmpty(item.size)) {
            filter.size = item.size
        }
        if (item.size > 0) {
            filter.products = item.products
        }
        if (!isEmpty(item.filters)) {
            filter.filters = this.normalizeArray(item.filters)
        }
        if (!isEmpty(item.search)) {
            filter.search = item.search
        }

        filter.setDefaultValue()

        return filter
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }

    getDisplayModeFromId (id) {
        if (config?.catalog?.filters?.display_mapping[id]) {
            return config.catalog.filters.display_mapping[id]
        }

        return 'default'
    }

    getIconFromId (id) {
        if (config?.catalog?.filters?.icon_mapping[id]) {
            return config.catalog.filters.icon_mapping[id]
        }

        return null
    }
}
